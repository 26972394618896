import { Card, CardContent, CardHeader, Typography } from "@mui/material";

const paperStyle = {
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  borderRadius: "16px",
};

const badgeStyle = {
  height: "200px",
};

const CustomCard = ({ cardHeader, sx, cardContentSx, children }) => {
  return (
    <>
      <Card elevation={0} sx={{ ...paperStyle, ...badgeStyle, ...sx }}>
        {cardHeader && (
          <CardHeader
            title={
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                }}
              >
                {cardHeader}
              </Typography>
            }
            sx={{
              pb: 0,
            }}
          ></CardHeader>
        )}

        <CardContent
          sx={{
            ...cardContentSx,
          }}
        >
          {children}
        </CardContent>
      </Card>
    </>
  );
};

export default CustomCard;

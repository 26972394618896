import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FormControl, Stack, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";

const TelegramLogin = ({onSubmit}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [sendCodeDisabled, setSendCodeDisabled] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "",
    password: "",
    phoneCode: "",
    apiId: "",
    apiHash: "",
    sessionId: "",
  });

  const handleLoginChange = (e) => {
    setErrorMessage("");
    setSendCodeDisabled(false);
    const { name, value } = e.target;
    setLoginFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleTelegramSendCode = () => {
    setSendCodeDisabled(true);
    if (
      loginFormData.phoneNumber &&
      loginFormData.apiHash &&
      loginFormData.apiId
    ) {
      fetch(`${backendUrl}/telegram-api/send-code/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(loginFormData),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 409) {
              throw new Error("Error!");
            } else {
              throw new Error("There was an error processing your request.");
            }
          }
          return response.json();
        })
        .then((data) => {
          setLoginFormData((prevData) => ({
            ...prevData,
            ["sessionId"]: data.sessionId,
          }));
          setErrorMessage("");
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          setErrorMessage(error.message);
        });
    } else {
      setErrorMessage(
        "You need to put phone number, API ID and API Hash in order to receive the phone code"
      );
    }
  };

  const handleTelegramLogin = () => {
    setSendCodeDisabled(true);
    if (
      loginFormData.phoneNumber &&
      loginFormData.apiHash &&
      loginFormData.apiId &&
      loginFormData.phoneCode &&
      loginFormData.password
    ) {
      fetch(`${backendUrl}/telegram-api/login/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(loginFormData),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 409) {
              throw new Error("Error");
            } else {
              throw new Error("There was an error processing your request.");
            }
          }
          return response.json();
        })
        .then((data) => {
          setErrorMessage("");
          onSubmit();
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          setErrorMessage(error.message);
        });
    } else {
      setErrorMessage("Please, complete the form.");
    }
  };
  return (
    <form>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <FormControl>
          <TextField
            id="apiId"
            label="API ID"
            name="apiId"
            variant="outlined"
            fullWidth
            required
            sx={{ flex: "0.8", width: "200px" }}
            value={loginFormData.apiId}
            onChange={handleLoginChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="apiHash"
            label="API Hash"
            name="apiHash"
            variant="outlined"
            fullWidth
            required
            sx={{ flex: "0.8", width: "200px" }}
            value={loginFormData.apiHash}
            onChange={handleLoginChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            variant="outlined"
            fullWidth
            required
            sx={{ flex: "0.8", width: "200px" }}
            value={loginFormData.phoneNumber}
            onChange={handleLoginChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="password"
            label="Password"
            name="password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            sx={{ flex: "0.8", width: "200px" }}
            value={loginFormData.password}
            onChange={handleLoginChange}
            InputProps={{
              endAdornment: (
                <CustomButton
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "lightgray",
                      boxShadow: "none",
                    },
                  }}
                  icon={showPassword ? VisibilityOffIcon : VisibilityIcon}
                />
              ),
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="phoneCode"
            label="Phone Code"
            name="phoneCode"
            variant="outlined"
            fullWidth
            required
            sx={{ flex: "0.8", width: "200px" }}
            value={loginFormData.phoneCode}
            onChange={handleLoginChange}
            InputProps={{
              endAdornment: (
                <CustomButton
                  icon={SendToMobileIcon}
                  sx={{
                    "&:hover": {
                      backgroundColor: "lightgray",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleTelegramSendCode}
                  disabled={sendCodeDisabled}
                />
              ),
            }}
          />
        </FormControl>
        <CustomButton
          buttonText={"Login"}
          color={"black"}
          onClick={handleTelegramLogin}
          disabled={
            !loginFormData.phoneCode &&
            !loginFormData.apiHash &&
            !loginFormData.apiId &&
            !loginFormData.phoneNumber &&
            !loginFormData.password
          }
        />
      </Stack>
    </form>
  );
};

export default TelegramLogin;

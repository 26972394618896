import { compareAsc, parse } from "date-fns";

export function fillEmptyDates(data) {
  if (Array.isArray(data)) {
    const allDates = Array.from(
      new Set(data.flatMap((series) => series.data.map((point) => point.x)))
    )
      .map((dateStr) => ({
        dateStr,
        date: parse(dateStr, "dd-MM-yyyy HH:mm", new Date()),
      }))
      .sort((a, b) => compareAsc(a.date, b.date))
      .slice(-30)
      .map(({ dateStr }) => dateStr);
    const updatedSeries = data.map((series) => ({
      ...series,
      data: allDates.map((date) => {
        const point = series.data.find((p) => p.x === date);
        return {
          x: date,
          y: point ? point.y : 0,
        };
      }),
    }));

    return updatedSeries;
  }
}

import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomButton from "../../global/CustomButton";
import { Loading } from "../../global/Loading";

const AdminDeleteChannel = ({ channel, onSubmit }) => {
  const [jwt] = useLocalState(null, "jwt");
  const [loadUpload, setLoadUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    deleteRemote: false,
  });

  const handleChangeDeleteRemote = (e) => {
    const checked = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      deleteRemote: checked,
    }));
  };

  const handleDeleteChannel = () => {
    if (channel.id) {
      fetch(`${backendUrl}/channels/${channel.id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.error || "There was an error processing your request."
            );
          }
          return response.json();
        })
        .then((data) => {
          onSubmit();
        })
        .catch((error) => {
          console.log(error);
          if (error.message === "Telegram channel not found") {
            onSubmit();
            setErrorMessage("");
          }
          console.error("There was an error!", error.message);
          setErrorMessage(error.message);
        });
    }
  };

  return (
    <form>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            color: "gray",
            alignItems: "center",
          }}
        >
          <InfoIcon />
          <Typography variant="body1">
            If you check this box, the channel will be deleted from the slack or
            telegram. Else, it will be only deleted from the database.
          </Typography>
        </Box>

        <Box
          sx={{
            display: !loadUpload ? "none" : "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Box>
        <FormControlLabel
          control={<Checkbox />}
          label="Delete remote channel"
          labelPlacement="start"
          sx={{
            mr: "20px",
            mt: "2px",
          }}
          checked={formData.deleteRemote}
          onChange={handleChangeDeleteRemote}
        />
        <CustomButton
          disabled={loadUpload}
          color={"lRed"}
          onClick={handleDeleteChannel}
          buttonText={"Delete"}
        />
      </Stack>
    </form>
  );
};

export default AdminDeleteChannel;

import FactCheckIcon from "@mui/icons-material/FactCheck";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { chartColors } from "../../theme";
import { useUserData } from "../../user";
import useComponentTypes from "../../utils/hooks/components/useComponentTypes";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import CustomModal from "../global/CustomModal";
import { Loading } from "../global/Loading";
import ComponentRadialChart from "./ComponentRadialChart";
import ComponentTypesChart from "./ComponentTypesChart";
import CreateEditComponentTypeForm from "./CreateEditComponentTypeForm";
import ReportingTable from "./ReportingTable";

const badgeFontStyles = {
  mainFontSize: ".8rem",
  titleFontSize: "0.875rem",
  digitFontSize: "2rem",
  chartForntSize: "1rem",
};

const defaultComponentType = {
  id: "",
  slug: "",
  name: "",
  versionsURL: "",
};

const Reporting = () => {
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [componentTypes, setComponentTypes] = useState([]);
  const [componentTypesChartData, setComponentTypesChartData] = useState([]);
  const [componentCount, setComponentCount] = useState(0);
  const [componentVerstionCount, setComponentVerstionCount] = useState(0);
  const [recentComponent, setRecentComponent] = useState();
  const [mode, setMode] = useState("create");
  const [formComponentType, setFormComponentType] =
    useState(defaultComponentType);
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteComponentTypeSlug, setDeleteComponentTypeSlug] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const { componentTypesSocketData } = useComponentTypes(jwt, backendUrl);
  const userData = useUserData();

  useEffect(() => {
    if (componentTypesSocketData) {
      setComponentTypes((prevComponentTypes) => {
        switch (componentTypesSocketData.actionType) {
          case "create":
            return prevComponentTypes.map((componentType) => {
              if (
                componentType.slug === componentTypesSocketData.data.type.slug
              ) {
                return {
                  ...componentType,
                  components: [
                    ...componentType.components,
                    componentTypesSocketData.data,
                  ],
                };
              }
              return componentType;
            });

          case "delete":
            return prevComponentTypes.map((componentType) => {
              return {
                ...componentType,
                components: componentType.components.filter(
                  (component) =>
                    component.id !== componentTypesSocketData.data.id
                ),
              };
            });

          case "update":
            return prevComponentTypes.map((componentType) => {
              return {
                ...componentType,
                components: componentType.components.map((component) => {
                  if (component.id === componentTypesSocketData.data.id) {
                    return {
                      ...component,
                      version: componentTypesSocketData.data.version,
                      updatedOn: componentTypesSocketData.data.updatedOn,
                    };
                  }
                  return component;
                }),
              };
            });

          default:
            return prevComponentTypes;
        }
      });
    }
  }, [componentTypesSocketData]);

  useEffect(() => {
    if (componentTypes.length > 0) {
      setComponentCount(
        componentTypes.reduce((sum, componentType) => {
          return sum + componentType.components.length;
        }, 0)
      );
      setComponentVerstionCount(
        componentTypes.reduce((sum, componentType) => {
          return (
            sum +
            componentType.components.filter((component) => component.version)
              .length
          );
        }, 0)
      );
      fetch(`${backendUrl}/components/latest/`, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setRecentComponent(data);
        })
        .catch((error) => {
          console.log(error);
        });
      createPieChartData(componentTypes);
    }
  }, [componentTypes]);

  useEffect(() => {
    fetch(`${backendUrl}/components/types/?includeComponents=true`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setComponentTypes(data);
        createPieChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, requestTrigger]);

  const handleEdit = (componentType) => {
    setFormComponentType(componentType);
    setMode("update");
    setModalOpen(true);
  };

  const handleSubmitForm = () => {
    setModalOpen(false);
    setRequestTrigger(!requestTrigger);
  };

  const handleNewComponentTypeButtonClick = () => {
    setFormComponentType(defaultComponentType);
    setMode("create");
    setModalOpen(true);
  };

  const handleDeleteComponentType = (componentType) => {
    setOpenConfirmDialog(true);
    setDeleteComponentTypeSlug(componentType.slug);
  };

  const handleConfirmDeleteComponentType = () => {
    setLoading(true);
    fetch(`${backendUrl}/components/types/${deleteComponentTypeSlug}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Component type not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setRequestTrigger(!requestTrigger);
        setLoading(false);
        setOpenConfirmDialog(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  function createPieChartData(data) {
    const chartData = [];
    data.forEach((componentType, index) => {
      chartData.push({
        id: componentType.slug,
        label: componentType.name,
        value: componentType.components.length,
        color: chartColors[index],
        visible: componentType.components.length > 0 ? true : false,
      });
    });

    setComponentTypesChartData(chartData);
  }

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid item xs={12} display={"flex"}>
            <Grid item xs={6}>
              <Header
                title={"Reporting"}
                subtitle={"Managing reported components"}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <CustomButton
                  type={"create"}
                  buttonText={"New Component Type"}
                  onClick={handleNewComponentTypeButtonClick}
                />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item container xs={12}>
              <Grid item container mt={"1%"} xs={12} spacing={3}>
                <Grid container item xs={4}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={7}
                        sx={{
                          display: "flex",
                          p: "15px",
                          justifyContent: "start",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "600",
                              fontSize: badgeFontStyles.titleFontSize,
                            }}
                          >
                            Total components reported
                          </Typography>
                        </Grid>
                        <Grid item xs={12} alignSelf={"end"}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "700",
                              fontSize: badgeFontStyles.digitFontSize,
                            }}
                          >
                            {componentCount}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <ComponentRadialChart
                          color={"blue"}
                          icon={SummarizeIcon}
                          value={100}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid container item xs={4}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={7}
                        sx={{
                          display: "flex",
                          p: "15px",
                          justifyContent: "start",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "600",
                              fontSize: badgeFontStyles.titleFontSize,
                            }}
                          >
                            Total versions reported
                          </Typography>
                        </Grid>
                        <Grid item xs={12} alignSelf={"end"}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "700",
                              fontSize: badgeFontStyles.digitFontSize,
                            }}
                          >
                            {componentVerstionCount}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <ComponentRadialChart
                          color={"green"}
                          icon={FactCheckIcon}
                          value={
                            (componentVerstionCount / componentCount) * 100
                          }
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid container item xs={4}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{
                          display: "flex",
                          p: "15px",
                          justifyContent: "start",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "600",
                              fontSize: badgeFontStyles.titleFontSize,
                            }}
                          >
                            Recent component report
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "1rem",
                            }}
                          >
                            {recentComponent && recentComponent.type.name}
                            {recentComponent &&
                              recentComponent.version &&
                              ` ${recentComponent.version}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                            }}
                          >
                            {recentComponent && recentComponent.channel.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <ReportingTable
                    components={componentTypes}
                    onDelete={handleDeleteComponentType}
                    onEdit={handleEdit}
                  />
                </Grid>
                {componentTypes && componentTypesChartData && (
                  <Grid item xs={4}>
                    <ComponentTypesChart
                      data={componentTypesChartData}
                      title={"Components by Type"}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <CustomModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <CreateEditComponentTypeForm
              componentType={formComponentType}
              onSubmit={handleSubmitForm}
              mode={mode}
            />
          </CustomModal>
          <Dialog
            PaperProps={{
              sx: {
                borderRadius: "16px",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                width: "400px",
                maxWidth: "90%",
              },
            }}
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
          >
            <DialogTitle>Confirm Delete Component Type</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete component? All reported
                components of this type will be deleted!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton
                sx={{
                  width: "80px",
                }}
                onClick={() => {
                  setOpenConfirmDialog(false);
                  setDeleteComponentTypeSlug("");
                }}
                buttonText="Cancel"
              />
              <CustomButton
                sx={{
                  width: "80px",
                }}
                onClick={handleConfirmDeleteComponentType}
                buttonText="Delete"
                color={"black"}
              />
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
};

export default Reporting;

import { FormControl, Grid, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import { Loading } from "../global/Loading";

const templateAPIScript = {
  type: "API",
  url: "",
  script: {
    structure: {
      apiStructureDeposit: "[]",
      apiStructureWithdraw: "[]",
    },
  },
  true: true,
};

const templateSCRAPEScript = {
  type: "SCRAPE",
  url: "",
  actions: [
    { action: "waitForSelector", selector: "tr:has(td img[alt='UST'])" },
    {
      action: "extract",
      depositStatusSelector:
        "tr:has(td img[alt='UST']) td:nth-child(2) div.status div:nth-child(1) span",
      withdrawalStatusSelector:
        "tr:has(td img[alt='UST']) td:nth-child(2) div.status div:nth-child(2) span",
    },
  ],
  true: "Active",
};

const EditExchangeProfileForm = ({ entity, onSubmit, mode }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [testResults, setTestResults] = useState();
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [formData, setFormData] = useState({
    exchange: {
      exchangeStatus: {
        failScoreThreshold: entity.exchangeStatus?.failScoreThreshold ?? 5.0,
        statusScript:
          entity.exchangeStatus?.statusScript ?? templateSCRAPEScript,
      },
    },
  });

  async function testScript() {
    setLoading(true);
    fetch(`${backendUrl}/exchanges/testScript/`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        statusScript: formData.exchange.exchangeStatus.statusScript,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        setTestResults(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
        setLoading(false);
      });
  }

  function handleAddForm(src) {
    if (src.name === "actions") {
      const actions = src.existing_value;
      const action = {
        action: "waitForSelector",
        selector: "",
      };
      actions.push(action);
      setFormData((prevData) => {
        return {
          exchange: {
            ...prevData.exchange,
            exchangeStatus: {
              ...prevData.exchange.exchangeStatus,
              statusScript: {
                ...prevData.exchange.exchangeStatus.statusScript,
                actions: actions,
              },
            },
          },
        };
      });
    }
  }

  function handleChangeForm(src) {
    if (src.name === "type" && src.existing_value != src.new_value) {
      switch (src.new_value?.toUpperCase()) {
        case "API":
          setFormData((prevData) => {
            return {
              exchange: {
                ...prevData.exchange,
                exchangeStatus: {
                  ...prevData.exchange.exchangeStatus,
                  statusScript: templateAPIScript,
                },
              },
            };
          });
          break;
        case "SCRAPE":
          setFormData((prevData) => {
            return {
              exchange: {
                ...prevData.exchange,
                exchangeStatus: {
                  ...prevData.exchange.exchangeStatus,
                  statusScript: templateSCRAPEScript,
                },
              },
            };
          });
          break;
        default:
          break;
      }
    } else if (src.name === "action" && src.existing_value != src.new_value) {
      const newSrc = src.updated_src.actions;
      let action = {};
      switch (src.new_value?.toLowerCase()) {
        case "waitforselector":
          action = {
            action: "waitForSelector",
            selector: "",
          };
          break;
        case "waitandclick":
          action = {
            action: "waitAndClick",
            selector: "",
          };
          break;
        case "click":
          action = {
            action: "click",
            selector: "",
          };
          break;
        case "waitfortimeout":
          action = {
            action: "waitForTimeout",
            time: 1000,
          };
          break;
        case "extract":
          action = {
            action: "extract",
            depositStatusSelector: "",
            withdrawalStatusSelector: "",
          };
          break;
        default:
          action = {
            action: "waitForSelector",
            selector: "",
          };
          break;
      }
      newSrc[src.namespace[1]] = action;
      setFormData((prevData) => {
        return {
          exchange: {
            ...prevData.exchange,
            exchangeStatus: {
              ...prevData.exchange.exchangeStatus,
              statusScript: {
                ...prevData.exchange.exchangeStatus.statusScript,
                actions: newSrc,
              },
            },
          },
        };
      });
    } else if (src.name === "index" && src.new_value != undefined) {
      const newIndex = src.new_value;
      const oldIndex = src.namespace[1];
      const newActions = [...src.updated_src.actions];

      [newActions[oldIndex], newActions[newIndex]] = [
        newActions[newIndex],
        newActions[oldIndex],
      ];

      setFormData((prevData) => {
        return {
          exchange: {
            ...prevData.exchange,
            exchangeStatus: {
              ...prevData.exchange.exchangeStatus,
              statusScript: {
                ...prevData.exchange.exchangeStatus.statusScript,
                actions: newActions,
              },
            },
          },
        };
      });
    } else {
      setFormData((prevData) => {
        return {
          exchange: {
            ...prevData.exchange,
            exchangeStatus: {
              ...prevData.exchange.exchangeStatus,
              statusScript: {
                ...src.updated_src,
              },
            },
          },
        };
      });
    }
  }

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => {
      return {
        exchange: {
          ...prevData.exchange,
          exchangeStatus: {
            ...prevData.exchange.exchangeStatus,
            [name]: value,
          },
        },
      };
    });
  };

  const handleSubmit = () => {
    fetch(`${backendUrl}/entities/${entity.baseEntity.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        onSubmit();
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        {" "}
        <InputLabel
          id="has-native-assets-label"
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Fail Score Threshold
        </InputLabel>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <TextField
            id="failScoreThreshold"
            name="failScoreThreshold"
            variant="outlined"
            inputProps={{
              step: ".1",
            }}
            fullWidth
            sx={{ flex: "0.8" }}
            type="number"
            value={formData.exchange.exchangeStatus.failScoreThreshold}
            onChange={handleInputChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {" "}
        <InputLabel
          id="has-native-assets-label"
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Deposits/Withdrawals Script
        </InputLabel>
      </Grid>
      <Grid item xs={12}>
        <ReactJson
          src={
            formData.exchange.exchangeStatus.statusScript ?? templateAPIScript
          }
          style={{
            border: "1px solid #c2c2c2",
            borderRadius: "4px",
            padding: "10px",
            fontSize: ".85rem",
            fontWeight: "400",
            maxHeight: "40vh",
            overflow: "scroll",
          }}
          name={null}
          iconStyle="square"
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={true}
          onAdd={handleAddForm}
          onEdit={handleChangeForm}
          onDelete={handleChangeForm}
        />
      </Grid>
      {loading ? (
        <Grid item xs={12} textAlign={"center"}>
          <Loading />
        </Grid>
      ) : (
        <>
          {testResults && (
            <>
              <Grid item xs={12}>
                {" "}
                <InputLabel
                  id="has-native-assets-label"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Response
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <ReactJson
                  src={testResults}
                  style={{
                    border: "1px solid #c2c2c2",
                    borderRadius: "4px",
                    padding: "10px",
                    fontSize: ".85rem",
                    fontWeight: "400",
                    maxHeight: "15vh",
                    overflow: "scroll",
                  }}
                  name={null}
                  iconStyle="square"
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={true}
                />
              </Grid>
            </>
          )}
        </>
      )}

      <Grid
        item
        xs={12}
        textAlign={"center"}
        display={"flex"}
        justifyContent={"center"}
        gap={"10px"}
      >
        <CustomButton
          buttonText={"Test"}
          color={"warning"}
          sx={{
            width: "80px",
          }}
          onClick={testScript}
        />
        <CustomButton
          buttonText={"Save"}
          color={"black"}
          sx={{
            width: "80px",
          }}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default EditExchangeProfileForm;

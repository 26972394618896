import { Container, Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "60px",
        borderTop: "1px solid rgb(227, 229, 233)",
        padding: "30px 10px",
        bottom: 0,
        marginTop: "40px",
      }}
    >
      <Typography variant="h5">
        © {new Date().getFullYear()} Cardano Foundation. All rights reserved.
      </Typography>
    </Container>
  );
}

export default Footer;

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState, version } from "react";
import { Link, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { chartColors } from "../../theme";
import useComponentTypes from "../../utils/hooks/components/useComponentTypes";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomTabsTable from "../global/CustomTabsTable";
import { Loading } from "../global/Loading";
import ComponentTypesChart from "./ComponentTypesChart";

const ReportingComponent = () => {
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { componentTypesSocketData } = useComponentTypes(jwt, backendUrl);
  const [loading, setLoading] = useState(true);
  const [componentType, setComponentType] = useState([]);
  const [componentTypesVersionsChartData, setComponentTypesVersionsChartData] =
    useState([]);
  const { componentTypeSlug } = useParams();

  function createPieChartData(data) {
    const chartData = [];
    const versionMap = {};
    data.components.forEach((component) => {
      const version = component.version || "Other";
      if (versionMap[version]) {
        versionMap[version] += 1;
      } else {
        versionMap[version] = 1;
      }
    });

    Object.keys(versionMap).forEach((version, index) => {
      chartData.push({
        id: version,
        label: version,
        value: versionMap[version],
        color: chartColors[index % chartColors.length],
        visible: true,
      });
    });

    setComponentTypesVersionsChartData(chartData);
  }

  const columns = [
    {
      field: "entity",
      headerName: "Entity",
      width: 150,
      valueGetter: (params) => {
        return params.row.channel.baseEntity;
      },
      renderCell: (params) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params.formattedValue &&
            params.formattedValue[params.formattedValue.entityType] ? (
              <>
                <img
                  src={
                    params.formattedValue[params.formattedValue.entityType].logo
                  }
                  alt="logo"
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                  }}
                />
                <Box>
                  <Link
                    to={`/entities/${params.formattedValue.slug}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {params.formattedValue.name}
                  </Link>
                </Box>
              </>
            ) : (
              <RemoveCircleOutlineIcon />
            )}
          </Box>
        );
      },
    },
    {
      field: "channel",
      headerName: "Channel",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.channel.name;
      },
    },
    {
      field: "version",
      headerName: "Version",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        if (params.formattedValue) {
          return params.formattedValue;
        }
        return <HelpOutlineIcon />;
      },
    },
  ];

  useEffect(() => {
    if (
      componentTypesSocketData &&
      componentType.slug == componentTypesSocketData.componentType
    ) {
      setComponentType((prevComponentType) => {
        switch (componentTypesSocketData.actionType) {
          case "create":
            return {
              ...prevComponentType,
              components: [
                ...prevComponentType.components,
                componentTypesSocketData.data,
              ],
            };
          case "delete":
            return {
              ...prevComponentType,
              components: prevComponentType.components.filter(
                (component) => component.id !== componentTypesSocketData.data.id
              ),
            };
          case "update":
            return {
              ...prevComponentType,
              components: prevComponentType.components.map((component) => {
                if (component.id === componentTypesSocketData.data.id) {
                  return {
                    ...component,
                    version: componentTypesSocketData.data.version,
                    updatedOn: componentTypesSocketData.data.updatedOn,
                  };
                }
                return component;
              }),
            };

          default:
            return prevComponentType;
        }
      });
    }
  }, [componentTypesSocketData]);

  useEffect(() => {
    if (
      componentType &&
      componentType.components &&
      componentType.components.length > 0
    ) {
      console.log(componentType);
      createPieChartData(componentType);
    }
  }, [componentType]);

  useEffect(() => {
    fetch(
      `${backendUrl}/components/types/${componentTypeSlug}?includeChannels=true&includeEntities=true`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setComponentType(data);
        createPieChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, componentTypeSlug]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container>
            {componentType && (
              <>
                <Grid item xs={6}>
                  <Header title={componentType?.name} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                ></Grid>
              </>
            )}
          </Grid>
          {componentType.components.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                Component details
              </Grid>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item container xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <CustomTabsTable
                    sxTable={{
                      height: "auto",
                    }}
                    sxPaper={{ height: "56vh", maxHeight: "450px" }}
                    data={componentType.components}
                    name={`${componentType.slug}-versions`}
                    columns={columns}
                  />
                </Grid>
                {componentType && componentTypesVersionsChartData && (
                  <Grid item xs={4}>
                    <ComponentTypesChart
                      title={"Versions"}
                      data={componentTypesVersionsChartData}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default ReportingComponent;

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { alpha, darken } from "@mui/material/styles";

const adjustColor = (color, amount) => {
  return color === "rgb(33, 43, 54)" ? alpha(color, 0.8) : darken(color, 0.1);
};

const colorScheme = {
  black: {
    backgroundColor: "rgb(33, 43, 54)",
    color: "white",
    disabled: {
      backgroundColor: "rgba(33, 43, 54, 0.2)",
      color: "white",
    },
  },
  lRed: {
    backgroundColor: "rgb(255, 230, 225)",
    color: "rgb(183, 29, 24)",
    hoverBgColor: "#ffccc3",
    disabled: {
      backgroundColor: "rgba(255, 230, 225, 0.5)",
      color: "rgba(183, 29, 24, 0.5)",
    },
  },
  green: {
    backgroundColor: "rgba(0, 167, 111, 1)",
    color: "rgb(255, 255, 255)",
    hoverBgColor: "rgba(0, 133, 88, 1)",
    disabled: {
      backgroundColor: "rgb(232, 245, 233)",
      color: "rgba(27, 94, 32, 0.5)",
      hoverBgColor: "#dcedc8",
    },
  },
  blue: {
    backgroundColor: "rgba(25, 118, 210, 1)",
    color: "white",
    hoverBgColor: "rgba(0, 82, 204, 1)",
    disabled: {
      backgroundColor: "rgb(225, 245, 254)",
      color: "rgba(0, 121, 191, 0.5)",
      hoverBgColor: "#cdeeff",
    },
  },
  warning: {
    backgroundColor: "rgb(255, 171, 0)",
    color: "white",
    hoverBgColor: "rgba(230, 156, 0, 1)",
    disabled: {
      backgroundColor: "rgba(255, 171, 0, 0.5)",
      color: "white",
    },
  },
};

const typeToIcon = {
  create: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
};

const typeToColor = {
  create: "black",
};

const CustomButton = ({
  type,
  buttonText,
  onClick,
  sx,
  disabled,
  color,
  icon: IconComponent,
}) => {
  const DefaultIcon = type ? typeToIcon[type] : null;
  const defaultColorScheme = type
    ? colorScheme[typeToColor[type]]
    : colorScheme[color];

  const hoverBgColor = defaultColorScheme?.hoverBgColor
    ? defaultColorScheme.hoverBgColor
    : adjustColor(
        sx?.backgroundColor ??
          defaultColorScheme?.backgroundColor ??
          "rgba(145, 158, 171, 0.16)"
      );

  return (
    <Box>
      <IconButton
        disableRipple
        onClick={() => onClick()}
        variant="contained"
        size="small"
        sx={{
          ...defaultColorScheme,
          borderRadius: "7px",
          textTransform: "none",
          fontWeight: "700",
          fontSize: "0.875rem",
          py: "10px",
          px: "10px",
          "&:hover": {
            backgroundColor: hoverBgColor,
            boxShadow: "none",
          },
          "&:disabled": defaultColorScheme?.disabled,
          ...sx,
        }}
        disabled={disabled}
      >
        {IconComponent ? (
          <IconComponent sx={{ mr: buttonText ? "5px" : 0 }} />
        ) : DefaultIcon ? (
          <DefaultIcon sx={{ mr: buttonText ? "5px" : 0 }} />
        ) : null}
        {buttonText}
      </IconButton>
    </Box>
  );
};
export default CustomButton;

import { createContext, useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";

const ExchangeStatusContext = createContext();

export const useExchangeStatus = () => {
  return useContext(ExchangeStatusContext);
};

export const ExchangeStatusProvider = ({ children, jwt }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [exchangeDEWISUpdate, setExchangeDEWISUpdate] = useState(null);
  const [exchangeSpecificUpdate, setExchangeSpecificUpdate] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (jwt) {
      const socket = io(`${backendUrl}/socket/exchange-status`, {
        transports: ["websocket"],
        query: {
          token: jwt,
        },
      });

      socketRef.current = socket;

      socket.on("connect", () => {
        console.log("Socket connected ExchangesStatus");
        socket.emit("joinGlobal");
      });

      socket.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      socket.on("connect_error", (err) => {
        console.error("Connect error:", err.message);
      });

      socket.on("globalStatusUpdate", (data) => {
        setExchangeDEWISUpdate(data);
      });

      socket.on("exchangeFailScore", (data) => {
        setExchangeSpecificUpdate(data);
      });

      return () => {
        socket.emit("leaveGlobal");
        socket.close();
      };
    }
  }, [jwt, backendUrl]);

  const joinExchangeRoom = (exchangeSlug) => {
    if (jwt) {
      console.log("Joined exchange room for", exchangeSlug)
      socketRef.current.emit("joinExchange", exchangeSlug);
    }
  };

  const leaveExchangeRoom = (exchangeSlug) => {
    if (jwt) {
      console.log("Left exchange room for", exchangeSlug)
      socketRef.current.emit("leaveExchange", exchangeSlug);
    }
  };

  return (
    <ExchangeStatusContext.Provider
      value={{
        globalUpdate: exchangeDEWISUpdate,
        exchangeUpdate: exchangeSpecificUpdate,
        joinExchangeRoom,
        leaveExchangeRoom,
      }}
    >
      {children}
    </ExchangeStatusContext.Provider>
  );
};

import { Box, CircularProgress, Stack } from "@mui/material";
import React from "react";

const colorScheme = {
  blue: {
    iconColor: "#00b8d9",
    progressColor: "#97dcec",
  },
  green: {
    iconColor: "#22c55d",
    progressColor: "#a4dfb3",
  },
  yellow: {
    iconColor: "#ffab00",
    progressColor: "#f7d39b",
  },
  red: {
    iconColor: "#ff5631",
    progressColor: "#faafa1",
  },
};

function ComponentRadialChart({ icon: IconComponent, color, value }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", width: 100, height: 100 }}
    >
      <IconComponent
        sx={{
          fontSize: 40,
          position: "absolute",
          zIndex: 1,
          color: colorScheme[color].iconColor,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        size={80}
        thickness={2}
        sx={{
          color: colorScheme[color].progressColor,
          position: "absolute",
        }}
      />
    </Stack>
  );
}

export default ComponentRadialChart;

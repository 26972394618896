export const mockDataEntities = [
  {
    id: 1,
    name: "Binance",
    type: "EXCHANGE",
    volume: 20000000,
    trustScore: 10,
    tradingPairs: "USDT/BTC/USD",
    nativeAssets: true,
    depositsWithdrawal: "UP/UP",
  },
  {
    id: 2,
    name: "Fireblocks",
    type: "CUSTODIAN",
    volume: 0,
    trustScore: 10,
    tradingPairs: "N/A",
    nativeAssets: true,
    depositsWithdrawal: "N/A",
  },
  {
    id: 3,
    name: "Blockfrost",
    type: "THIRD PARTY",
    volume: 0,
    trustScore: 10,
    tradingPairs: "N/A",
    nativeAssets: true,
    depositsWithdrawal: "N/A",
  },
  {
    id: 4,
    name: "Nami",
    type: "WALLET",
    volume: 0,
    trustScore: 10,
    tradingPairs: "N/A",
    nativeAssets: true,
    depositsWithdrawal: "N/A",
  },
  {
    id: 5,
    name: "Kraken",
    type: "EXCHANGE",
    volume: 15000000,
    trustScore: 10,
    tradingPairs: "USDT/BTC",
    nativeAssets: false,
    depositsWithdrawal: "UP/UP",
  },
  {
    id: 6,
    name: "Coinbase",
    type: "EXCHANGE",
    volume: 10000000,
    trustScore: 10,
    tradingPairs: "USDT/USD",
    nativeAssets: true,
    depositsWithdrawal: "UP/UP",
  },
];

export const mockDataUser = {
  name: "Vladislav Kudrin",
  email: "vladislav.kudrin@cardanofoundation.org",
  access: "admin",
};

export const mockDataVolume = [
  {
    id: "binance",
    color: "hsl(267, 70%, 50%)",
    data: [
      {
        x: "05-10 13:20",
        y: 56047652,
      },
      {
        x: "05-10 00:00",
        y: 66676645,
      },

      {
        x: "05-11 02:40",
        y: 24997588,
      },
      {
        x: "05-11 16:00",
        y: 46347131,
      },
      {
        x: "05-12 05:20",
        y: 14458123,
      },
      {
        x: "05-12 18:40",
        y: 30771398,
      },
      {
        x: "05-13 08:00",
        y: 71733381,
      },
      {
        x: "05-13 21:20",
        y: 41957163,
      },
      {
        x: "05-14 10:40",
        y: 52669191,
      },
      {
        x: "05-15 00:00",
        y: 25517341,
      },
    ],
  },
  {
    id: "bitrue",
    color: "hsl(117, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 62695673,
      },
      {
        x: "05-10 13:20",
        y: 28241358,
      },
      {
        x: "05-11 02:40",
        y: 75726785,
      },
      {
        x: "05-11 16:00",
        y: 73508524,
      },
      {
        x: "05-12 05:20",
        y: 70248793,
      },
      {
        x: "05-12 18:40",
        y: 20758839,
      },
      {
        x: "05-13 08:00",
        y: 56821963,
      },
      {
        x: "05-13 21:20",
        y: 12559735,
      },
      {
        x: "05-14 10:40",
        y: 18805030,
      },
      {
        x: "05-15 00:00",
        y: 43028509,
      },
    ],
  },
  {
    id: "cex",
    color: "hsl(14, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 23998526,
      },
      {
        x: "05-10 13:20",
        y: 37318613,
      },
      {
        x: "05-11 02:40",
        y: 65071826,
      },
      {
        x: "05-11 16:00",
        y: 33427280,
      },
      {
        x: "05-12 05:20",
        y: 73000142,
      },
      {
        x: "05-12 18:40",
        y: 59071843,
      },
      {
        x: "05-13 08:00",
        y: 11166965,
      },
      {
        x: "05-13 21:20",
        y: 78570912,
      },
      {
        x: "05-14 10:40",
        y: 36210579,
      },
      {
        x: "05-15 00:00",
        y: 31580132,
      },
    ],
  },
  {
    id: "bybit",
    color: "hsl(114, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 46131171,
      },
      {
        x: "05-10 13:20",
        y: 79963489,
      },
      {
        x: "05-11 02:40",
        y: 40699738,
      },
      {
        x: "05-11 16:00",
        y: 45360356,
      },
      {
        x: "05-12 05:20",
        y: 59071834,
      },
      {
        x: "05-12 18:40",
        y: 74110794,
      },
      {
        x: "05-13 08:00",
        y: 49301557,
      },
      {
        x: "05-13 21:20",
        y: 39849484,
      },
      {
        x: "05-14 10:40",
        y: 10112554,
      },
      {
        x: "05-15 00:00",
        y: 64104906,
      },
    ],
  },
  {
    id: "coinbase",
    color: "hsl(49, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 71579149,
      },
      {
        x: "05-10 13:20",
        y: 34811198,
      },
      {
        x: "05-11 02:40",
        y: 18186786,
      },
      {
        x: "05-11 16:00",
        y: 39162706,
      },
      {
        x: "05-12 05:20",
        y: 10391827,
      },
      {
        x: "05-12 18:40",
        y: 75648628,
      },
      {
        x: "05-13 08:00",
        y: 15264878,
      },
      {
        x: "05-13 21:20",
        y: 15274098,
      },
      {
        x: "05-14 10:40",
        y: 17019649,
      },
      {
        x: "05-15 00:00",
        y: 58035022,
      },
    ],
  },
  {
    id: "kraken",
    color: "hsl(298, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 48285885,
      },
      {
        x: "05-10 13:20",
        y: 64346524,
      },
      {
        x: "05-11 02:40",
        y: 79298949,
      },
      {
        x: "05-11 16:00",
        y: 14708426,
      },
      {
        x: "05-12 05:20",
        y: 54302273,
      },
      {
        x: "05-12 18:40",
        y: 66559090,
      },
      {
        x: "05-13 08:00",
        y: 45531077,
      },
      {
        x: "05-13 21:20",
        y: 10985886,
      },
      {
        x: "05-14 10:40",
        y: 65558646,
      },
      {
        x: "05-15 00:00",
        y: 10596257,
      },
    ],
  },
  {
    id: "bitstamp",
    color: "hsl(345, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 13407813,
      },
      {
        x: "05-10 13:20",
        y: 13661885,
      },
      {
        x: "05-11 02:40",
        y: 68665139,
      },
      {
        x: "05-11 16:00",
        y: 63699731,
      },
      {
        x: "05-12 05:20",
        y: 66395077,
      },
      {
        x: "05-12 18:40",
        y: 75088650,
      },
      {
        x: "05-13 08:00",
        y: 13495935,
      },
      {
        x: "05-13 21:20",
        y: 26496294,
      },
      {
        x: "05-14 10:40",
        y: 66003222,
      },
      {
        x: "05-15 00:00",
        y: 23943581,
      },
    ],
  },
  {
    id: "gemini",
    color: "hsl(326, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 30813285,
      },
      {
        x: "05-10 13:20",
        y: 32767220,
      },
      {
        x: "05-11 02:40",
        y: 67389442,
      },
      {
        x: "05-11 16:00",
        y: 14188549,
      },
      {
        x: "05-12 05:20",
        y: 41613980,
      },
      {
        x: "05-12 18:40",
        y: 71380540,
      },
      {
        x: "05-13 08:00",
        y: 75116114,
      },
      {
        x: "05-13 21:20",
        y: 32242159,
      },
      {
        x: "05-14 10:40",
        y: 69604412,
      },
      {
        x: "05-15 00:00",
        y: 70611547,
      },
    ],
  },
  {
    id: "okex",
    color: "hsl(228, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 63347482,
      },
      {
        x: "05-10 13:20",
        y: 13024116,
      },
      {
        x: "05-11 02:40",
        y: 41943263,
      },
      {
        x: "05-11 16:00",
        y: 58119223,
      },
      {
        x: "05-12 05:20",
        y: 30749468,
      },
      {
        x: "05-12 18:40",
        y: 76572131,
      },
      {
        x: "05-13 08:00",
        y: 32635628,
      },
      {
        x: "05-13 21:20",
        y: 45525653,
      },
      {
        x: "05-14 10:40",
        y: 72261917,
      },
      {
        x: "05-15 00:00",
        y: 58118717,
      },
    ],
  },
  {
    id: "huobi",
    color: "hsl(21, 70%, 50%)",
    data: [
      {
        x: "05-10 00:00",
        y: 57113441,
      },
      {
        x: "05-10 13:20",
        y: 62914938,
      },
      {
        x: "05-11 02:40",
        y: 27636095,
      },
      {
        x: "05-11 16:00",
        y: 71207019,
      },
      {
        x: "05-12 05:20",
        y: 55173423,
      },
      {
        x: "05-12 18:40",
        y: 14976479,
      },
      {
        x: "05-13 08:00",
        y: 38566080,
      },
      {
        x: "05-13 21:20",
        y: 71712256,
      },
      {
        x: "05-14 10:40",
        y: 34639365,
      },
      {
        x: "05-15 00:00",
        y: 52632649,
      },
    ],
  },
];

export const mockDataHardForks = [
  {
    id: 1,
    name: "VASIL",
    slug: "vasil",
    ready: 35,
    inProgress: 20,
    notStarted: 71,
    upcoming: false,
  },
  {
    id: 2,
    name: "Valentine",
    slug: "valentine",
    ready: 25,
    inProgress: 31,
    notStarted: 70,
    upcoming: false,
  },
  {
    id: 3,
    name: "CHANG",
    slug: "chang",
    ready: 51,
    inProgress: 25,
    notStarted: 50,
    upcoming: true,
  },
];

for (let i = 4; i <= 25; i++) {
  let ready = Math.floor(Math.random() * 51);
  let inProgress = Math.floor(Math.random() * (51 - ready));
  let notStarted = 126 - ready - inProgress;

  mockDataHardForks.push({
    id: i,
    name: `ENTRY${i}`,
    slug: `entry${i}`,
    ready: ready,
    inProgress: inProgress,
    notStarted: notStarted,
    upcoming: false,
  });
}

export function applyStatusToExchanges(exchanges, statusCounts) {
  let statuses = [];
  for (let status in statusCounts) {
    for (let i = 0; i < statusCounts[status]; i++) {
      statuses.push(status);
    }
  }

  statuses.sort(() => Math.random() - 0.5);

  if (exchanges.length !== statuses.length) {
    throw new Error(
      "Mismatch between number of exchanges and total status counts."
    );
  }

  return exchanges.map((exchange, index) => {
    return {
      ...exchange,
      status: statuses[index],
    };
  });
}

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useUserData } from "../../user";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";

const CreateEditComponentTypeForm = ({ componentType, onSubmit, mode }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState(componentType);
  const userData = useUserData();

  const handleInputChange = (e) => {
    setErrorMessage("");
    let { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log(formData)
    const url =
      mode === "update" && formData.id
        ? `${backendUrl}/components/types/${formData.slug}`
        : `${backendUrl}/components/types/`;
    const method = mode === "update" ? "PUT" : "POST";

    fetch(url, {
      method: method,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Component type not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        onSubmit();
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          maxHeight: "80vh",
          "&::-webkit-scrollbar": {
            width: "0px",
            height: "0px",
            background: "transparent",
          },
          scrollbarWidth: "none",
          MsOverflowStyle: "none",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <FormControl>
            <TextField
              id="name"
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              required
              sx={{ flex: "0.8", width: "100%", mt: "10px" }}
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              id="versionsURL"
              label="Versions URL"
              name="versionsURL"
              variant="outlined"
              fullWidth
              sx={{ flex: "0.8", width: "100%", mt: "10px" }}
              value={formData.versionsURL}
              onChange={handleInputChange}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            flex: "0.2",
            justifyContent: "flex-end",
            textAlign: "center",
          }}
        >
          {errorMessage && (
            <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
              {errorMessage}
            </Box>
          )}

          <CustomButton
            buttonText={mode == "update" ? "Save" : "Create"}
            color={"black"}
            onClick={handleSubmit}
          />
        </Box>
      </Stack>
    </form>
  );
};

export default CreateEditComponentTypeForm;

import { useTheme } from "@emotion/react";
import { Box, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSessionState } from "../../utils/hooks/global/useSessionStorage";

import { mockDataUser } from "../../data/mockData";
import DebugMode from "./DebugMode";

export const login = async () => {
  return mockDataUser;
};

const Login = () => {
  const location = useLocation();
  const theme = useTheme();
  const debug = process.env.REACT_APP_DEBUG;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [storedUrl, setStoredUrl] = useSessionState(location.pathname, "url");

  const google = () => {
    setStoredUrl(location.pathname);
    window.open(`${backendUrl}/auth/google/login`, "_self");
  };

  return (
    <>
      <Box mt={"150px"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <img
              src={`${process.env.PUBLIC_URL}/images/cardano-logo.png`}
              alt="Cardano"
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
          {debug === "true" ? (
            <DebugMode />
          ) : (
            <Box mt={"50px"}>
              <Button
                onClick={async () => {
                  google();
                }}
                variant="outlined"
                color={theme.palette.mode === "dark" ? "secondary" : "primary"}
              >
                Login with Google
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Login;

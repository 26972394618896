import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box } from "@mui/material";
import CustomButton from "../../global/CustomButton";
import CustomTabsTable from "../../global/CustomTabsTable";

const AdminPartnersTable = ({ partners, onAction }) => {
  const tabsFunctions = {
    all: {
      func: function (data) {
        return data;
      },
      label: "All",
      color: "default",
    },
    active: {
      func: function (data) {
        return data.filter((partner) => {
          return !partner.apiToken.revoked;
        });
      },
      label: "Active",
      color: "green",
    },
    revoked: {
      func: function (data) {
        return data.filter((partner) => {
          return partner.apiToken.revoked;
        });
      },
      label: "Revoked",
      color: "red",
    },
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 170,
      flex: 1,
    },
    {
      field: "apiToken",
      headerName: "API Token",
      type: "string",
      headerAlign: "center",
      align: "left",
      flex: 1,
      valueGetter: (params) => {
        return params.value.token;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CustomButton
              buttonText={
                !params.row.apiToken.revoked ? "Revoke" : "Regenerate"
              }
              icon={
                !params.row.apiToken.revoked ? RemoveCircleIcon : AutorenewIcon
              }
              onClick={
                !params.row.apiToken.revoked
                  ? () => {
                      onAction(params.row.slug, "revoke");
                    }
                  : () => onAction(params.row.slug, "regenerate")
              }
              sx={{
                padding: "5px 10px 5px 5px",
                fontSize: "0.675rem",
             
              }}
            />
          
          </Box>
        );
      },
    },
  ];

  return (
    <CustomTabsTable
      tabs={tabsFunctions}
      columns={columns}
      name={"adminPartners"}
      data={partners}
    />
  );
};

export default AdminPartnersTable;

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function TruncateWords({ words, displayCount, separator, sx }) {

  const visibleWords = words.slice(0, displayCount).join(separator);
  const hiddenWords = words.join(separator);

  return (
    <div>
      <Typography sx={{
        fontSize: "0.75rem",
        ...sx
      }}>
        {visibleWords}
        {words.length > displayCount && (
          <Tooltip title={<h2 style={{ color: "lightblue" }}>{hiddenWords}</h2>} arrow >
            <span  style={{ cursor: 'pointer' }}> ...</span>
          </Tooltip>
        )}
      </Typography>
    </div>
  );
}

export default TruncateWords;

import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fcfcfc",
  borderRadius: "16px",
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  pt: 2,
  px: 4,
  pb: 3,
};

const channelTypes = [
  {
    name: "Telegram",
    value: "telegram",
  },
  {
    name: "Slack",
    value: "slack",
  },
];

const entityTypes = [
  {
    name: "Exchange",
    value: "exchange",
  },
  {
    name: "Wallet",
    value: "wallet",
  },
];

export default function ExcludeModal({
  isOpen,
  onClose,
  channels,
  formData,
  onChange,
}) {
  return (
    <Fragment>
      <Modal open={isOpen} onClose={onClose} aria-labelledby="exclude-modal">
        <Grid
          container
          sx={{
            ...style,
          }}
        >
          <Grid item xs={12}>
            {" "}
            <h2 id="child-modal-title">Exclude options</h2>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Autocomplete
                name="channelType"
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                onChange={(event, value) =>
                  onChange(event, value, "channelType")
                }
                value={
                  channelTypes.filter(
                    (channelType) =>
                      formData.excludeOptions.channelType === channelType.value
                  )[0] || null
                }
                id="tags-outlined"
                options={channelTypes}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Channel Type"
                    placeholder="Exclude telegram or slack"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                name="entityTypes"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, value) =>
                  onChange(event, value, "entityTypes")
                }
                value={entityTypes.filter((entityType) =>
                  formData.excludeOptions.entityTypes.includes(entityType.value)
                )}
                multiple
                id="tags-outlined"
                options={entityTypes}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Entity Type"
                    placeholder="Exclude entity types"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                name="channelIds"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => onChange(event, value, "channels")}
                value={channels.filter((channel) =>
                  formData.excludeOptions.channelIds.includes(channel.id)
                )}
                multiple
                id="tags-outlined"
                options={channels}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderTags={(value, getTagProps) => (
                  <Box
                    sx={{
                      maxHeight: 150,
                      overflow: "auto",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Channels"
                    placeholder="Exclude single channels"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"} pt={3}>
            <CustomButton
              onClick={onClose}
              buttonText={"Save"}
              color={"black"}
            />
          </Grid>
        </Grid>
      </Modal>
    </Fragment>
  );
}

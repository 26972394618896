import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";

function calculatePercentage(value, total) {
  return (value / total) * 100;
}

function RadialChart({ theme = "green", data, label, startAngle=0, endAngle=360, showLabel = false, offsetYLabel = 4.5, offsetYValue = 4.5 }) {
  const [chartConfig, setChartConfig] = useState({
    series: [],
    options: {},
  });

  const dataRadialChartRef = useRef(data);

  useEffect(() => {
    dataRadialChartRef.current = data;
    setChartConfig((prevConfig) => ({
      ...prevConfig,
      series: [
        calculatePercentage(
          dataRadialChartRef.current.data,
          dataRadialChartRef.current.total
        ),
      ],
    }));
  }, [data]);

  const formatterFunction = (value) => {
    
    return value.toFixed(1) + "%";
  };

  useEffect(() => {
    const config = {
      options: {
        chart: {
          type: "radialBar",
        },
        colors: [theme],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            startAngle: startAngle,
            endAngle: endAngle,
            dataLabels: {
              name: {
                show: showLabel,
                fontSize: ".8rem",
                offsetY: offsetYLabel,
                formatter: function (w) {
                  return w
                },
                color: "black",
                fontWeight: "0"
              },
              value: {
                fontSize: ".8rem",
                show: true,
                fontWeight: "700",
                offsetY: offsetYValue,
                formatter: formatterFunction,
              },
              total: {
                show: !showLabel,
                formatter: function (w) {
                  const total =
                    w.globals.seriesTotals.reduce((a, b) => a + b, 0) /
                    w.globals.series.length;
                  return total.toFixed(1) + "%";
                },
              },
            },
          },
        },
        labels: [label],
        stroke: {
          lineCap: "round",
        },
      },
    };

    setChartConfig((prevConfig) => ({
      ...prevConfig,
      options: config.options,
    }));
  }, [theme, label]);

  return (
    <ReactApexChart
      options={chartConfig.options}
      series={chartConfig.series}
      type="radialBar"
    />
  );
}

export default RadialChart;

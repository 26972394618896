import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import CustomButton from "./CustomButton";

const CustomAlert = ({ message, severity, timeToFadeOut, onClose, sx }) => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(0);
      setTimeout(onClose, 500);
    }, timeToFadeOut);

    return () => clearTimeout(timer);
  }, [timeToFadeOut, onClose]);

  return (
    <Alert
      severity={severity}
      action={<CustomButton onClick={onClose} icon={CloseIcon} />}
      sx={{
        top: 0,
        position: "fixed",
        width: "95%",
        fontSize: "1.5rem",
        zIndex: 2000,
        transition: "opacity 0.5s ease-in-out",
        opacity,
        ...sx,
        alignItems: "center",
        '& .MuiAlert-action': {
          padding: 0, // Remove padding here
          marginRight: 0, // Optional: Remove margin if needed
        },
      }}
    >
      {message}
    </Alert>
  );
};

export default CustomAlert;

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Paper, Tabs, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserData } from "../../../user";
import CustomButton from "../../global/CustomButton";
import CustomTab from "../../global/CustomTab";
import CustomTabsTable from "../../global/CustomTabsTable";

const CustomTableButtonComponent = ({
  condition,
  title,
  buttonText,
  icon,
  onClickAction,
  disabledCondition,
}) => (
  <Tooltip title={condition ? title : ""}>
    <Box>
      <CustomButton
        buttonText={buttonText}
        icon={icon}
        onClick={onClickAction}
        sx={{
          padding: "5px 10px 5px 5px",
          fontSize: "0.675rem",
          width: "80px",
        }}
        disabled={disabledCondition}
      />
    </Box>
  </Tooltip>
);

const AdminChannelsTable = ({
  channels,
  onAssign,
  onSet,
  onClaim,
  onDelete,
}) => {
  const userData = useUserData();
  const [currentTab, setCurrentTab] = useState("all");

  const location = useLocation();
  const [filterValue, setFilterValue] = useState(
    new URLSearchParams(location.search).get("filter")
  );
  const [tabValueQuery, setTabValueQuery] = useState(
    new URLSearchParams(location.search).get("tab")
  );
  const [filterModel, setFilterModel] = useState({ items: [] });

  useEffect(() => {
    if (tabValueQuery) {
      switch (tabValueQuery) {
        case "pending":
          setCurrentTab("isPending");
          break;
        case "set":
          setCurrentTab("all");
          break;
        default:
          setCurrentTab("all");
          break;
      }
    }
  }, [tabValueQuery]);

  useEffect(() => {
    if (filterValue && channels && channels.length > 0) {
      const [columnField, filterVal] = filterValue.split("=");
      if (columnField && filterVal) {
        setFilterModel({
          items: [
            {
              id: 1,
              field: decodeURIComponent(columnField),
              operator: "equals",
              value: decodeURIComponent(filterVal),
            },
          ],
        });
      }
      setFilterValue(null);
    }
  }, [filterValue, channels]);

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);
  };

  const tabsFunctions = {
    all: {
      func: function (data) {
        return data.filter((channel) => {
          return channel.baseEntity;
        });
      },
      label: "Set",
      color: "default",
    },
    isPending: {
      func: function (data) {
        return data.filter((channel) => {
          return !channel.baseEntity;
        });
      },
      label: "Pending",
      color: "yellow",
    },
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 170,
      flex: 1,
    },
    {
      field: "channelType",
      headerName: "Channel Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const value = params.value || "";
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
    {
      field: "chatId",
      headerName: "Chat ID",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "owner",
      headerName: "Owner",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        if (params.value) {
          return params.value.user.displayName;
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const { id, channelType, chatId, name, slug } = params.row;
        const channelData = {
          id,
          channelType,
          chatId,
          name,
          slug,
          subscriptionIds: params.row.subscriptions.map((sub) => sub.id),
        };
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <>
              {currentTab === "all" ? (
                <>
                  <CustomTableButtonComponent
                    condition={params.row.owner?.user.email !== userData.email}
                    title={
                      !userData.profile.telegramCredentials
                        ? "Go to profile and connect telegram"
                        : ""
                    }
                    buttonText={
                      params.row.owner?.user.email !== userData.email
                        ? "Claim"
                        : "Assign"
                    }
                    icon={
                      params.row.owner?.user.email !== userData.email
                        ? ErrorIcon
                        : AssignmentIndIcon
                    }
                    onClickAction={
                      params.row.owner?.user.email !== userData.email
                        ? () => onClaim(params.row)
                        : () => onAssign(params.row)
                    }
                    disabledCondition={!userData.profile.telegramCredentials}
                  />
                  {params.row.owner?.user.email === userData.email && (
                    <CustomTableButtonComponent
                      condition={
                        params.row.owner?.user.email !== userData.email
                      }
                      title={
                        !userData.profile.telegramCredentials
                          ? "Go to profile and connect telegram"
                          : ""
                      }
                      buttonText={"Delete"}
                      icon={DeleteIcon}
                      onClickAction={() => onDelete(params.row)}
                      disabledCondition={!userData.profile.telegramCredentials}
                    />
                  )}
                </>
              ) : (
                <CustomTableButtonComponent
                  onClickAction={() => onSet(params.row)}
                  condition={true}
                  buttonText={"Set"}
                  icon={AutoAwesomeIcon}
                />
              )}
            </>
          </Box>
        );
      },
    },
  ];

  return (
    <CustomTabsTable
      tabs={tabsFunctions}
      defaultValue={currentTab}
      columns={columns}
      name={"adminChannels"}
      data={channels}
      onTabChange={handleTabChange}
      filterModel={filterModel}
    />
  );
};

export default AdminChannelsTable;

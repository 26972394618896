import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CreateEntityForm from "../../entities/CreateForm";
import CustomButton from "../../global/CustomButton";

const AdminSetChannelForm = ({ channel, entities, onSubmit }) => {
  const [jwt] = useLocalState(null, "jwt");
  const [createEntity, setCreateEntity] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    entityName: "",
    entitySlug: "",
  });
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleEntityCreated = (createdEntity) => {
    setFormData((prevData) => ({
      ...prevData,
      entitySlug: createdEntity.slug,
      entityName: createdEntity.name,
    }));
    entities.push(createdEntity);
    setCreateEntity(false);
  };

  const handleSetChannel = (event) => {
    if (event) {
      event.preventDefault();
    }

    fetch(`${backendUrl}/channels/${channel.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        baseEntitySlug: formData.entitySlug,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("Something is wrong..");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        onSubmit();
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  const handleAutocompleteChange = (event, value, reason) => {
    event.preventDefault();
    setCreateEntity(false);
    if (reason === "selectOption" || reason === "createOption") {
      const entity = entities.find(
        (entity) => entity.name === (value.name || value)
      );
      if (!entity) {
        setCreateEntity(true);
        setFormData((prevData) => ({
          ...prevData,
          entityName: value.name || value,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          entitySlug: entity.slug,
          entityName: entity.name,
        }));
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSetChannel}>
        <Stack
          direction={"column"}
          width={"100%"}
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {entities && (
            <>
              <FormControl>
                <Autocomplete
                  id="entities-channels"
                  options={entities}
                  onChange={handleAutocompleteChange}
                  freeSolo
                  getOptionLabel={(option) =>
                    typeof option === "string"
                      ? option
                      : option.wallet
                      ? option.wallet.name
                      : option.exchange
                      ? option.exchange.name
                      : "Unknown"
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <img
                        src={
                          option.wallet
                            ? option.wallet.logo
                            : option.exchange
                            ? option.exchange.logo
                            : ""
                        }
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "10px",
                        }}
                      />
                      {option.wallet
                        ? option.wallet.name
                        : option.exchange
                        ? option.exchange.name
                        : "Unknown"}
                    </Box>
                  )}
                  sx={{ width: 200 }}
                  renderInput={(params) => <TextField {...params}  label="Entity"/>}
                />
              </FormControl>
              {!createEntity && (
                <CustomButton
                  onClick={handleSetChannel}
                  color={"black"}
                  sx={{
                    width: "80px",
                  }}
                  disabled={!formData.entitySlug}
                  buttonText={"Set"}
                />
              )}
            </>
          )}
        </Stack>
      </form>
      {createEntity && (
        <Box
          sx={{
            width: "60%",
            margin: "auto",
            marginTop: "15px",
          }}
        >
          <CreateEntityForm
            onSubmit={handleEntityCreated}
            initDataExchange={{
              name: formData.entityName,
              hasNativeAssets: false,
              trustScore: "",
            }}
            initDataWallet={{
              name: formData.entityName,
              hasNativeAssets: false,
              hasStaking: false,
              trustScore: "",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default AdminSetChannelForm;

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../scenes/global/CustomAlert";
import { useExchangeStatus } from "../utils/contexts/entities/exchanges/exchangeStatusContext";

const ExchangeStatusAlert = () => {
  const { globalUpdate } = useExchangeStatus();
  const [userAlerts, setUserAlerts] = useState([]);

  useEffect(() => {
    if (globalUpdate && globalUpdate.changedDEWIs) {
      const alerts = globalUpdate.changedDEWIs.map((status, index) => ({
        id: Date.now() + index,
        type: status.type,
        status: status.status,
        name: globalUpdate.exchangeName,
      }));
      setUserAlerts((prevAlerts) => [...prevAlerts, ...alerts]);
    }
  }, [globalUpdate]);

  const handleCloseAlert = (alertId) => {
    setUserAlerts((prevAlerts) =>
      prevAlerts.filter((alert) => alert.id !== alertId)
    );
  };

  return (
    <>
      {userAlerts.map((alert) => (
        <Box display={"flex"} justifyContent={"center"} key={alert.id}>
          <CustomAlert
            key={alert.id}
            onClose={() => handleCloseAlert(alert.id)}
            message={`${alert.name} ${alert.type} is ${alert.status}`}
            severity={alert.status === "up" ? "success" : "error"}
            timeToFadeOut={10000}
            sx={{
              border: `1px solid ${
                alert.status === "up" ? "#004b50" : "#7a0916"
              }`,
              marginTop: `${userAlerts.indexOf(alert) * 70 + 3}px`,
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default ExchangeStatusAlert;

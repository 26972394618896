import { useEffect } from "react";
import { useSetUserData, useUserData } from "../../../user";
import { useUserActivity } from "../../contexts/users/userActivityContext";

const useActivityTracker = () => {
  const { socket, userStatus } = useUserActivity();
  const userData = useUserData();
  const setUserData = useSetUserData();

  useEffect(() => {
    if (!socket || !userData) return;

    let inactivityTimeout;

    const handleActivity = () => {
      clearTimeout(inactivityTimeout);

      inactivityTimeout = setTimeout(() => {
        if (userData && userData.isOnline) {
          socket.emit("status-update", {
            userId: userData.id,
            status: "inactive",
          });
          setUserData((prevData) => ({
            ...prevData,
            isOnline: false,
            lastSeen: new Date(),
          }));
        }
      }, 15 * 60 * 1000); 

      if (userData && !userData.isOnline) {
        socket.emit("status-update", { userId: userData.id, status: "active" });
        setUserData((prevData) => ({
          ...prevData,
          isOnline: true,
          lastSeen: new Date(),
        }));
      }
    };

    const handleWindowClose = () => {
      if (userData && userData.isOnline) {
        socket.emit("status-update", {
          userId: userData.id,
          status: "inactive",
        });
        setUserData((prevData) => ({
          ...prevData,
          isOnline: false,
          lastSeen: new Date(),
        }));
      }
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [socket, userStatus, userData, setUserData]);
};

export default useActivityTracker;

import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Container, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Header } from "../../../components/Header";
import { useUserData } from "../../../user";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomButton from "../../global/CustomButton";
import CustomModal from "../../global/CustomModal";
import DiagonalButtonWrapper from "../../global/DiagonalButtonWrapper";
import { Loading } from "../../global/Loading";
import AdminAssignChannel from "./AdminAssignChannel";
import AdminChannelsTable from "./AdminChannelsTable";
import AdminCreateChannelForm from "./AdminCreateEditChannelForm";
import AdminDeleteChannel from "./AdminDeleteChannel";
import AdminImportChannels from "./AdminImportChannels";
import AdminSetChannelForm from "./AdminSetChannelForm";

function AdminChannels() {
  const [jwt] = useLocalState(null, "jwt");
  const [channels, setChannels] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState();
  const [modalContent, setModalContent] = useState("");
  const [requestTrigger, setRequestTrigger] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const userData = useUserData();

  const handleSubmitForm = () => {
    setModalOpen(false);
    setRequestTrigger(!requestTrigger);
  };

  const handleAssign = (channel) => {
    setChannel(channel);
    handleOpenModal("assign");
  };

  const handleDelete = (channel) => {
    setChannel(channel);
    handleOpenModal("delete");
  };

  const handleClaim = (channel) => {
    setChannel(channel);
    handleOpenModal("claim");
  };

  const handleSet = (channel) => {
    setChannel(channel);
    handleOpenModal("set");
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleExport = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "Chat Id;Channel Name;Channel Type;Entity Slug;Entity Name;Entity Type;Owner\n";

    channels.forEach((channel) => {
      csvContent +=
        [
          `${channel.chatId}`,
          `${channel.name}`,
          `${channel.channelType}`,
          `${channel.baseEntity ? channel.baseEntity.slug : ""}`,
          `${channel.baseEntity ? channel.baseEntity.name : ""}`,
          `${channel.baseEntity ? channel.baseEntity.entityType : ""}`,
          `${channel.owner ? channel.owner.user.email : ""}`,
        ].join(";") + "\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "channels.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const fetchEntities = fetch(`${backendUrl}/entities`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }).then((response) => response.json());

    const fetchProfiles = fetch(`${backendUrl}/profiles`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }).then((response) => response.json());

    const fetchChannels = fetch(
      `${backendUrl}/channels/?includeUser=true&includeEntity=true`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      }
    ).then((response) => response.json());

    Promise.all([fetchEntities, fetchProfiles, fetchChannels])
      .then((results) => {
        setEntities(results[0]);
        setProfiles(results[1]);
        setChannels(results[2]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
      });
  }, [jwt, backendUrl, requestTrigger]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Header
                title="Remote channels"
                subtitle="Managing remote channels"
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <>
                  <DiagonalButtonWrapper>
                    <CustomButton
                      color="green"
                      buttonText={"Import"}
                      onClick={() => handleOpenModal("import")}
                      icon={FileUploadIcon}
                      sx={{
                        borderBottom: "none",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                    <CustomButton
                      color="warning"
                      buttonText={"Export"}
                      icon={DownloadIcon}
                      onClick={handleExport}
                      sx={{
                        borderTop: "none",
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                      }}
                      disabled={!channels}
                    />
                  </DiagonalButtonWrapper>

                  <CustomButton
                    type={"create"}
                    buttonText={"Create Channel"}
                    onClick={() => handleOpenModal("create")}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box
                m={"40px 0 0 0"}
                height={"75vh"}
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px solid #e0e0e0",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "none",
                    backgroundColor: "#f4f6f8",
                    color: "rgb(99, 115, 129)",
                    fontSize: "13px",
                  },
                  "& .MuiDataGrid-virtualScroller": {},
                  "& .MuiDataGrid-footerContainer": {
                    paddingBottom: "0px",
                  },
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600 !important",
                    overflow: "visible !important",
                  },
                }}
              >
                <Paper
                  sx={{
                    height: "95%",
                    borderRadius: "16px",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  }}
                >
                  <AdminChannelsTable
                    channels={channels}
                    onModalOpen
                    onAssign={handleAssign}
                    onSet={handleSet}
                    onClaim={handleClaim}
                    onDelete={handleDelete}
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            sx={{
              width: "40%",
              maxHeight: "80%",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {modalContent === "create" && (
              <AdminCreateChannelForm
                onSubmit={handleSubmitForm}
                profiles={profiles}
                entities={entities}
              />
            )}
            {modalContent === "import" && (
              <AdminImportChannels
                entities={entities}
                onSubmit={() => handleSubmitForm()}
              />
            )}
            {modalContent === "assign" && (
              <AdminAssignChannel
                channel={channel}
                profiles={profiles}
                onSubmit={() => handleSubmitForm()}
              />
            )}
            {modalContent === "delete" && (
              <AdminDeleteChannel
                channel={channel}
                profiles={profiles}
                onSubmit={() => handleSubmitForm()}
              />
            )}
            {modalContent === "set" && (
              <AdminSetChannelForm
                channel={channel}
                profiles={profiles}
                entities={entities}
                onSubmit={() => handleSubmitForm()}
              />
            )}
          </CustomModal>
        </Container>
      )}
    </>
  );
}

export default AdminChannels;

import { Box, Container, Grid, Paper, Tab, Tabs } from "@mui/material";

import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { useUserData } from "../../user";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import CustomModal from "../global/CustomModal";
import { Loading } from "../global/Loading";
import CreateForm from "./CreateForm";
import EntitiesTable from "./EntitiesTable";

const Entities = () => {
  const userData = useUserData();
  const [isModalOpen, setModalOpen] = useState(false);
  const [entities, setEntites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const handleAddEntityButtonClick = () => {
    setModalOpen(true);
  };

  const handleSubmit = () => {
    setModalOpen(false);
    setRequestTrigger(!requestTrigger);
  };

  useEffect(() => {
    fetch(
      `${backendUrl}/entities/?includeChannels=true&includeVolumes=true&includeAssets=true&includeStatuses=true`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setEntites(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, requestTrigger]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Header title="Entities" subtitle="Managing entities" />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <CustomButton
                  type={"create"}
                  buttonText={"New Entity"}
                  onClick={handleAddEntityButtonClick}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <EntitiesTable initialEntities={entities} />
            </Grid>
          </Grid>
          <CustomModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <CreateForm onSubmit={() => handleSubmit()} />
          </CustomModal>
        </Container>
      )}
    </>
  );
};

export default Entities;

import { Box, Container, Grid, Paper, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Header } from "../../../components/Header";
import { useUserActivity } from "../../../utils/contexts/users/userActivityContext";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomTabsTable from "../../global/CustomTabsTable";
import { Loading } from "../../global/Loading";

function AdminUsers() {
  const [jwt] = useLocalState(null, "jwt");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { userStatus } = useUserActivity();

  useEffect(() => {
    if (userStatus) {
      const newAllUsers = users.map((user) => {
        if (user.id === userStatus?.userId) {
          return {
            ...user,
            isOnline: userStatus?.isOnline,
            lastActive: userStatus?.lastActive,
          };
        }
        return user;
      });
      setUsers(newAllUsers);
    }
  }, [userStatus]);

  const tabsFunctions = {
    all: {
      func: function (data) {
        return data;
      },
      label: "All",
      color: "default",
    },
    isOnline: {
      func: function (data) {
        return data.filter((user) => user.isOnline);
      },
      label: "Active",
      color: "green",
    },
    isAdmin: {
      func: function (values) {
        return values.filter((user) => user.isAdmin);
      },
      label: "Admin",
      color: "yellow",
    },
    offline: {
      func: function (values) {
        return values.filter((user) => !user.isOnline);
      },
      label: "Offline",
      color: "gray",
    },
  };

  useEffect(() => {
    fetch(`${backendUrl}/users?includeProfiles=true`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl]);

  const columns = [
    {
      field: "displayName",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                params.row.profile.profileImageIPFS
                  ? `https://ipfs.io/ipfs/${params.row.profile.profileImageIPFS}`
                  : `${process.env.PUBLIC_URL}/images/cardano-logo-dark.png`
              }
              alt="logo"
              style={{
                width: "25px",
                height: "25px",
                marginRight: "10px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            {params.formattedValue}
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "lastActive",
      headerName: "Last seen",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const formattedDate = date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        });
        return formattedDate;
      },
    },
  ];
  return (
    <>
      {" "}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Header title="Users" subtitle="Managing users" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomTabsTable
                columns={columns}
                tabs={tabsFunctions}
                name={"adminUsers"}
                data={users}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export default AdminUsers;

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "../../components/Search";
import { tokens } from "../../theme";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [jwt, setJwt] = useLocalState(null, "jwt");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate("/profile");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    fetch(`${backendUrl}/users/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setJwt(null);
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      sx={{
        position: "sticky",
        top: "0",
        zIndex: "1000",
        backgroundColor: "#fcfcfc",
        borderBottom: "1px solid rgb(227, 229, 233)",
        marginBottom: "25px",
      }}
    >
      <Search />

      <Box display="flex">
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: "7px",
          }}
        >
          <SettingsOutlinedIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={goToProfile}>
          <PersonOutlinedIcon sx={{ mr: "5px" }} />
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutOutlinedIcon sx={{ mr: "5px" }} />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Topbar;

import {
  Box,
  Container,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Header } from "../../../components/Header";
import { useUserData } from "../../../user";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomButton from "../../global/CustomButton";
import CustomModal from "../../global/CustomModal";
import { Loading } from "../../global/Loading";
import AdminPartnersTable from "./AdminPartnersTable";

function AdminPartners() {
  const [jwt] = useLocalState(null, "jwt");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [requestTrigger, setRequestTrigger] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const userData = useUserData();

  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    fetch(`${backendUrl}/partners/`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPartners(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, requestTrigger]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetch(`${backendUrl}/partners/`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        ...formData,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("Partner already exists!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        setModalOpen(false);
        setFormData({
          name: ""
        })
        setRequestTrigger(!requestTrigger);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  const handleRevokeRegenerate = (slug, action) => {
    fetch(`${backendUrl}/partners/${slug}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        action: action,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("Partner not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        setModalOpen(false);
        setRequestTrigger(!requestTrigger);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Header title="Partners" subtitle="Managing API Partners" />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <>
                  <CustomButton
                    type={"create"}
                    buttonText={"Add Partner"}
                    onClick={handleOpenModal}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box
                m={"40px 0 0 0"}
                height={"75vh"}
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px solid #e0e0e0",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "none",
                    backgroundColor: "#f4f6f8",
                    color: "rgb(99, 115, 129)",
                    fontSize: "13px",
                  },
                  "& .MuiDataGrid-virtualScroller": {},
                  "& .MuiDataGrid-footerContainer": {
                    paddingBottom: "0px",
                  },
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600 !important",
                    overflow: "visible !important",
                  },
                }}
              >
                <Paper
                  sx={{
                    height: "95%",
                    borderRadius: "16px",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  }}
                >
                  <AdminPartnersTable
                    partners={partners}
                    onAction={handleRevokeRegenerate}
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            sx={{
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"center"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                id="name"
                label="Partner name"
                name="name"
                variant="outlined"
                required
                sx={{ flex: "0.8" }}
                value={formData.name}
                onChange={handleInputChange}
              />

              <Box
                sx={{
                  flex: "0.2",
                  justifyContent: "flex-end",
                  textAlign: "center",
                }}
              >
                {errorMessage && (
                  <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
                    {errorMessage}
                  </Box>
                )}
                <CustomButton
                  onClick={handleSubmit}
                  sx={{
                    width: "70px",
                  }}
                  disabled={!formData.name}
                  buttonText={"Add"}
                  color={"black"}
                />
              </Box>
            </Stack>
          </CustomModal>
        </Container>
      )}
    </>
  );
}

export default AdminPartners;

import BarChartIcon from "@mui/icons-material/BarChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CountUp } from "use-count-up";
import { Header } from "../../components/Header";
import { chartColors } from "../../theme";
import { useUserData } from "../../user";
import { useExchangeStatus } from "../../utils/contexts/entities/exchanges/exchangeStatusContext";
import { formatAndSortVolumesForLineChart } from "../../utils/entity/entity";
import { fillEmptyDates } from "../../utils/graphs/graphs";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import { Badge } from "../global/Badge";
import BarChart from "../global/BarChart";
import CustomButton from "../global/CustomButton";
import LineChart from "../global/LineChart";
import { Loading } from "../global/Loading";
import PieChart from "../global/PieChart";

const paperStyle = {
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  borderRadius: "16px",
};
const cardContentStyle = {
  height: "400px",
};

function getVolume(exchange, type) {
  const volumes = exchange[`${type}Volumes`];
  return volumes[0] ? volumes[0].volume : 0;
}

function sortByVolumeType(exchanges, type) {
  return [...exchanges].sort((a, b) => {
    const lastVolumeA = a[type][a[type].length - 1]?.volume || 0;
    const lastVolumeB = b[type][b[type].length - 1]?.volume || 0;
    return lastVolumeB - lastVolumeA;
  });
}

function sortByBaseTarget(exchanges) {
  return {
    base: sortByVolumeType(exchanges, "baseVolumes"),
    target: sortByVolumeType(exchanges, "targetVolumes"),
  };
}

function filterTopExchanges(top, { base, target }) {
  return {
    base: base.slice(0, top),
    target: target.slice(0, top),
    length: top,
  };
}

function calculateTotalVolumes(exchanges) {
  return {
    totalVolumeBase: exchanges.reduce(
      (accumulator, currentValue) =>
        accumulator + getVolume(currentValue, "base"),
      0
    ),
    totalVolumeTarget: exchanges.reduce(
      (accumulator, currentValue) =>
        accumulator + getVolume(currentValue, "target"),
      0
    ),
  };
}

function transformLineChartToBarChart(lineChartData) {
  const barChart = {};
  const keysSet = new Set();
  const legendsObj = {};

  lineChartData.forEach((exchange) => {
    keysSet.add(exchange.id);
    legendsObj[exchange.id] = {
      id: exchange.id,
      color: exchange.color,
      name: exchange.name,
    };
    exchange.data.forEach((point) => {
      if (!barChart[point.x]) {
        barChart[point.x] = { date: point.x };
      }
      barChart[point.x][exchange.id] = point.y;
      barChart[point.x][`${exchange.id}Color`] = exchange.color;
    });
  });

  const keys = Array.from(keysSet);

  return {
    chart: Object.values(barChart),
    keys: keys,
    legends: legendsObj,
  };
}

function createChartData(topExchanges, totalVolumes) {
  const chartData = {
    baseLineChart: [],
    basePieChart: [],
    baseBarChart: { chart: [], keys: [], legends: [] },
    targetLineChart: [],
    targetPieChart: [],
    targetBarChart: { chart: [], keys: [], legends: [] },
    baseTopVolumes: 0,
    targetTopVolumes: 0,
  };

  for (let i = 0; i < topExchanges.length; i++) {
    const exchangeBase = topExchanges.base[i];
    const exchangeTarget = topExchanges.target[i];

    const dataBase = formatAndSortVolumesForLineChart(exchangeBase, "base");
    const dataTarget = formatAndSortVolumesForLineChart(
      exchangeTarget,
      "target"
    );
    const baseVolume = getVolume(topExchanges.base[i], "base");
    const targetVolume = getVolume(topExchanges.target[i], "target");

    if (baseVolume !== 0) {
      chartData["baseLineChart"].push({
        id: exchangeBase.slug,
        data: dataBase.map((dataItem) => ({
          y: dataItem.y,
          x: dataItem.formattedDate,
        })),
        visible: i < 5,
        color: chartColors[i],
        name: exchangeBase.name,
      });

      chartData["basePieChart"].push({
        id: exchangeBase.slug,
        label: exchangeBase.name,
        value: baseVolume,
        color: chartColors[i],
      });

      chartData.baseTopVolumes += baseVolume;
    }

    if (targetVolume !== 0) {
      chartData["targetLineChart"].push({
        id: exchangeTarget.slug,
        data: dataTarget.map((dataItem) => ({
          y: dataItem.y,
          x: dataItem.formattedDate,
        })),
        visible: i < 5,
        color: chartColors[i],
        name: exchangeTarget.name,
      });

      chartData["targetPieChart"].push({
        id: exchangeTarget.slug,
        label: exchangeTarget.name,
        value: targetVolume,
        color: chartColors[i],
      });

      chartData.targetTopVolumes += targetVolume;
    }
  }

  const otherVolumeBase = (
    totalVolumes.totalVolumeBase - chartData.baseTopVolumes
  ).toFixed(2);
  const otherVolumeTarget = (
    totalVolumes.totalVolumeTarget - chartData.targetTopVolumes
  ).toFixed(2);

  if (otherVolumeBase > 0) {
    chartData["basePieChart"].push({
      id: "other",
      label: "Other",
      value: otherVolumeBase,
      color: chartColors[10],
    });
  }

  if (otherVolumeTarget > 0) {
    chartData["targetPieChart"].push({
      id: "other",
      label: "Other",
      value: otherVolumeTarget,
      color: chartColors[10],
    });
  }

  chartData.baseLineChart = fillEmptyDates(chartData.baseLineChart);
  chartData.targetLineChart = fillEmptyDates(chartData.targetLineChart);

  chartData.baseBarChart = transformLineChartToBarChart(
    chartData.baseLineChart
  );
  chartData.targetBarChart = transformLineChartToBarChart(
    chartData.targetLineChart
  );

  return chartData;
}

const Dashboard = () => {
  const [dataLineBase, setDataLineBase] = useState([]);
  const [dataLineTarget, setDataLineTarget] = useState([]);
  const [dataPieBase, setDataPieBase] = useState([]);
  const [dataPieTarget, setDataPieTarget] = useState([]);
  const [dataBarBase, setDataBarBase] = useState([]);
  const [dataBarBaseKeys, setDataBarBaseKeys] = useState({});
  const [dataBarTarget, setDataBarTarget] = useState([]);
  const [dataBarTargetKeys, setDataBarTargetKeys] = useState({});
  const [totalVolumeBase, setTotalVolumeBase] = useState();
  const [totalVolumeTarget, setTotalVolumeTarget] = useState();
  const [loading, setLoading] = useState(true);
  const [requestTrigger, setRequestTrigger] = useState(false);
  const userData = useUserData();
  const { globalUpdate } = useExchangeStatus();

  const [chart, setChart] = useState({
    base: "line",
    target: "line",
  });

  const [allExchanges, setAllExchanges] = useState();
  const [exchangesTracked, setExchangesTracked] = useState();
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (globalUpdate && globalUpdate.statusUpdate && exchangesTracked) {
      const newTrackedExchanges = exchangesTracked.map((entity) => {
        if (entity.slug === globalUpdate.exchangeSlug) {
          return {
            ...entity,
            exchangeStatus: {
              ...entity.exchangeStatus,
              depositAllowed: globalUpdate.statusUpdate.depositAllowed,
              withdrawalAllowed: globalUpdate.statusUpdate.withdrawalAllowed,
            },
          };
        }
        return entity;
      });
      setExchangesTracked(newTrackedExchanges);
    }
  }, [globalUpdate, exchangesTracked]);

  useEffect(() => {
    fetch(`${backendUrl}/exchanges`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setExchangesTracked(data.filter((exchange) => exchange.exchangeStatus));
        setDataForCharts(data);
        setAllExchanges(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, requestTrigger]);

  function handleUpdateEntities() {
    setLoading(true);
    fetch(`${backendUrl}/entities/update`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRequestTrigger(!requestTrigger);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  }

  function setDataForCharts(exchanges) {
    if (Array.isArray(exchanges)) {
      const exchangesSortedByType = sortByBaseTarget(exchanges);
      const topExchanges = filterTopExchanges(10, exchangesSortedByType);

      const totalVolumes = calculateTotalVolumes(exchanges);
      setTotalVolumeBase(totalVolumes.totalVolumeBase);
      setTotalVolumeTarget(totalVolumes.totalVolumeTarget);

      const chartData = createChartData(topExchanges, totalVolumes);
      setDataLineBase(chartData.baseLineChart);
      setDataLineTarget(chartData.targetLineChart);
      setDataPieBase(chartData.basePieChart);
      setDataPieTarget(chartData.targetPieChart);
      setDataBarBase(chartData.baseBarChart.chart);
      setDataBarBaseKeys({
        keys: chartData.baseBarChart.keys,
        legends: chartData.baseBarChart.legends,
      });
      setDataBarTarget(chartData.targetBarChart.chart);
      setDataBarTargetKeys({
        keys: chartData.targetBarChart.keys,
        legends: chartData.targetBarChart.legends,
      });
    }
  }
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <>
          <Container maxWidth={"xl"}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Header title={"Dashboard"} subtitle={"Managing dashboard"} />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {userData.isAdmin && (
                  <CustomButton
                    color={"green"}
                    buttonText={"Update entities"}
                    icon={UpdateIcon}
                    onClick={handleUpdateEntities}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Badge
                  title={
                    <CountUp
                      isCounting
                      end={allExchanges?.length | 0}
                      duration={1.5}
                    />
                  }
                  subtitle="Tracked"
                  theme="blue"
                />
              </Grid>
              <Grid item xs={3}>
                <Badge
                  title={
                    <CountUp
                      isCounting
                      end={
                        exchangesTracked?.filter(
                          (exchange) =>
                            exchange.exchangeStatus?.withdrawalAllowed &&
                            exchange.exchangeStatus?.depositAllowed
                        ).length | 0
                      }
                      duration={1.5}
                    />
                  }
                  subtitle="Online"
                  theme="green"
                />
              </Grid>
              <Grid item xs={3}>
                <Badge
                  title={
                    <CountUp
                      isCounting
                      end={
                        exchangesTracked?.filter(
                          (exchange) =>
                            !exchange.exchangeStatus?.withdrawalAllowed ||
                            !exchange.exchangeStatus?.depositAllowed
                        ).length | 0
                      }
                      duration={1.5}
                    />
                  }
                  subtitle="Offline"
                  theme="red"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card elevation={0} sx={paperStyle}>
                  <CardHeader
                    sx={{
                      pb: 0,
                    }}
                    title={
                      <Box>
                        <Typography variant="h4" sx={{ fontWeight: 700 }}>
                          Base Volume ($)
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          mt={3}
                          gap={1}
                        >
                          <CustomButton
                            buttonText={"Line"}
                            icon={ShowChartIcon}
                            color={"black"}
                            onClick={() => {
                              if (chart["base"] !== "line") {
                                setChart((prevData) => ({
                                  ...prevData,
                                  base: "line",
                                }));
                              }
                            }}
                          />
                          <CustomButton
                            buttonText={"Bar"}
                            icon={BarChartIcon}
                            color={"black"}
                            onClick={() => {
                              if (chart["base"] !== "bar") {
                                setChart((prevData) => ({
                                  ...prevData,
                                  base: "bar",
                                }));
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    }
                    titleTypographyProps={{ component: "div" }}
                  />
                  <CardContent
                    sx={{
                      pt: 0,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} md={6} lg={8}>
                        <Box sx={cardContentStyle}>
                          {chart["base"] === "line" && (
                            <LineChart
                              data={dataLineBase}
                              yTitle={"Volume"}
                              xTitle={"Time"}
                            />
                          )}
                          {chart["base"] === "bar" && (
                            <BarChart
                              data={dataBarBase}
                              indexBy={"date"}
                              keys={dataBarBaseKeys.keys}
                              legends={dataBarBaseKeys.legends}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={cardContentStyle}>
                          <PieChart
                            data={dataPieBase}
                            totalVolume={totalVolumeBase}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card elevation={0} sx={paperStyle}>
                  <CardHeader
                    sx={{
                      pb: 0,
                    }}
                    title={
                      <Box>
                        <Typography variant="h4" sx={{ fontWeight: 700 }}>
                          Target Volume ($)
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          mt={3}
                          gap={1}
                        >
                          <CustomButton
                            buttonText={"Line"}
                            icon={ShowChartIcon}
                            color={"black"}
                            onClick={() => {
                              if (chart["target"] !== "line") {
                                setChart((prevData) => ({
                                  ...prevData,
                                  target: "line",
                                }));
                              }
                            }}
                          />
                          <CustomButton
                            buttonText={"Bar"}
                            icon={BarChartIcon}
                            color={"black"}
                            onClick={() => {
                              if (chart["target"] !== "bar") {
                                setChart((prevData) => ({
                                  ...prevData,
                                  target: "bar",
                                }));
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    }
                    titleTypographyProps={{ component: "div" }}
                  />
                  <CardContent
                    sx={{
                      pt: 0,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} md={6} lg={8}>
                        <Box sx={cardContentStyle}>
                          {chart["target"] === "line" && (
                            <LineChart
                              data={dataLineTarget}
                              yTitle={"Volume"}
                              xTitle={"Time"}
                            />
                          )}
                          {chart["target"] === "bar" && (
                            <BarChart
                              data={dataBarTarget}
                              indexBy={"date"}
                              keys={dataBarTargetKeys.keys}
                              legends={dataBarTargetKeys.legends}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={cardContentStyle}>
                          <PieChart
                            data={dataPieTarget}
                            totalVolume={totalVolumeTarget}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default Dashboard;

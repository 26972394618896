import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { CountUp } from "use-count-up";
import { turncateString } from "../../utils/global";

const PieChart = (props) => {
  const [dataPieChart, setDataPieChart] = useState(props.data);

  useEffect(() => {
    setDataPieChart(props.data);
  }, [props.data]);


  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <>
        {props.totalVolume && (
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              color: "#FFFFFF",
              fontSize: "1.25rem",
            }}
          >
            <CountUp
              isCounting
              end={props.totalVolume}
              duration={1.5}
              formatter={(value) =>
                value.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }
            />{" "}
            $
          </text>
        )}
      </>
    );
  };

  return (
    <ResponsivePie
      layers={["arcs", "arcLinkLabels", "legends", CenteredMetric]}
      data={dataPieChart}
      valueFormat={">-,.2f"}
      colors={({ data }) => data.color}
      arcLinkLabel={({ data }) => {
        return turncateString(data.label, 11)
      }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.8}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={20}
      arcLinkLabelsThickness={2}
      arcLinkLabelsDiagonalLength={5}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={1}
    />
  );
};

export default PieChart;

import {
  Box,
  Tab,
} from "@mui/material";

export default function CustomTab({
  label,
  count,
  textBackground,
  textColorOption,
  ...props
}) {
  return (
    <Tab
      label={
        <Box display="flex" alignItems="center" fontSize={props.fontSize}>
          <span>{label}</span>
          <Box
            component="span"
            ml={1}
            bgcolor={textBackground}
            color={textColorOption}
            borderRadius="4px"
            width="24px"
            height="24px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            px={0.5}
            py={0.2}
            fontWeight={700}
            fontSize={props.fontSize}
          >
            {count}
          </Box>
        </Box>
      }
      {...props}
    />
  );
}

import { Modal, Paper } from "@mui/material";

const defaultStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fcfcfc",
  borderRadius: "16px",
  boxShadow:
  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  p: 4,
};

const CustomModal = ({ isOpen, onClose, sx, children }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper sx={{
        ...defaultStyle,
        ...sx
      }}>
        {children}
      </Paper>
    </Modal>
  );
};

export default CustomModal;

import FileUploadIcon from "@mui/icons-material/FileUpload";
import InfoIcon from "@mui/icons-material/Info";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../user";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomButton from "../../global/CustomButton";
import { Loading } from "../../global/Loading";
import AdminImportChannelsTable from "./AdminImportChannelsTable";

function AdminImportChannels({ entities, onSubmit }) {
  const [jwt] = useLocalState(null, "jwt");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const [loadUpload, setLoadUpload] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileChannels, setFileChannels] = useState([]);
  const [sortedChannels, setSortedChannels] = useState([]);
  const [isBotInvited, setIsBotInvited] = useState(false);
  const [formState, setFormState] = useState("login");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const userData = useUserData();

  useEffect(() => {
    if (userData && userData.profile.telegramCredentials) {
      setFormState("import");
    }
  }, [userData]);

  useEffect(() => {
    let sortedChannels;
    if (fileChannels.length > 0) {
      sortedChannels = fileChannels.map((channel) => {
        if (channel["Channel Name"]) {
          let entitySlug = channel["Entity Slug"];
          if (!entitySlug) {
            const matchingEntity = entities.find((entity) =>
              channel["Channel Name"]
                .toLowerCase()
                .includes(entity.slug.toLowerCase())
            );
            entitySlug = matchingEntity ? matchingEntity.slug : "";
          }
          return {
            channelName: channel["Channel Name"],
            chatId: channel["Chat Id"],
            owner: channel["Owner"],
            baseEntitySlug: entitySlug,
            channelType: channel["Channel Type"],
          };
        }
      });
    }
    setSortedChannels(sortedChannels);
  }, [fileChannels]);

  const handleProfileRedirect = () => {
    navigate("/profile");
  };

  const onLoaded = () => {
    setLoadUpload(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setLoadUpload(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
      const normalizedText = text.replace(/\r\n|\r/g, "\n");
      const rows = normalizedText
        .split("\n")
        .filter((row) => row.trim().length > 0);
      const headers = rows[0].split(";");
      const data = rows.slice(1).map((row) => {
        const values = row.split(";");
        let obj = {};

        headers.forEach((header, index) => {
          obj[header] = values[index];
        });

        return obj;
      });
      setFileChannels(data);
    };

    reader.readAsText(file);

    setFile(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleChangeInviteBot = (e) => {
    const checked = e.target.checked;
    setIsBotInvited(checked);
  };

  const handleInputChange = (e, value, chatId) => {
    setErrorMessage("");
    const entitySlug = value.slug;
    setSortedChannels((prevChannels) =>
      prevChannels.map((channel) => {
        if (channel.chatId === chatId) {
          return { ...channel, baseEntitySlug: entitySlug };
        } else {
          return channel;
        }
      })
    );
  };

  const handleImportChannels = () => {
    if (sortedChannels) {
      setLoadUpload(true);
      fetch(`${backendUrl}/channels/import/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({
          channels: sortedChannels,
          isBotInvited: isBotInvited,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || "There was an error processing your request."
            );
          }
          return response.json();
        })
        .then((data) => {
          setErrorMessage("");
          if (data.status === 200) {
            setTimeout(() => {
              setLoadUpload(false);
              onSubmit();
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          setLoadUpload(false);
          setErrorMessage(error.message);
        });
    }
  };

  return (
    <>
      {formState === "login" && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            color: "gray",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              color: "gray",
              alignItems: "center",
            }}
          >
            <InfoIcon />
            <Typography variant="body1">
              Please, connect your profile to telegram in order to import
              channels.
            </Typography>
          </Box>
          <CustomButton
            buttonText={"Profile"}
            color={"blue"}
            icon={PersonOutlinedIcon}
            onClick={handleProfileRedirect}
          />
        </Box>
      )}
      {formState === "import" && (
        <form>
          <Stack
            direction={"column"}
            width={"100%"}
            spacing={2}
            sx={{ display: "flex", alignItems: "flex-end" }}
          >
            <Box
              sx={{
                display: loadUpload ? "none" : "block",
                width: "100%",
              }}
            >
              {fileChannels.length <= 0 ? (
                <Box
                  {...getRootProps()}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "16px",
                    width: "100%",
                    height: "300px",
                    backgroundColor: "rgba(145, 158, 171, 0.08)",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "rgba(145, 158, 171, 0.2)",
                    },
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "800",
                    }}
                  >
                    Drop or Select file
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    width={"80%"}
                    variant="body1"
                  >
                    Please use exported file as a template
                  </Typography>
                </Box>
              ) : (
                <AdminImportChannelsTable
                  sortedData={sortedChannels}
                  entities={entities}
                  onLoaded={() => onLoaded()}
                  handleAutocompleteChange={(e, value, chatId) =>
                    handleInputChange(e, value, chatId)
                  }
                />
              )}
            </Box>
            {loadUpload && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </Box>
            )}

            {errorMessage && (
              <Box
                width={"100%"}
                textAlign={"center"}
                sx={{ color: "red", marginTop: 2, marginBottom: 2 }}
              >
                {errorMessage}
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label="Also invite bot to the channels (only Telegram)"
                labelPlacement="start"
                sx={{
                  mr: "20px",
                  mt: "2px",
                }}
                checked={isBotInvited}
                onChange={handleChangeInviteBot}
              />
              <CustomButton
                disabled={loadUpload || fileChannels.length <= 0}
                color={"green"}
                icon={FileUploadIcon}
                buttonText={"Import"}
                onClick={handleImportChannels}
              />
            </Box>
          </Stack>
        </form>
      )}
    </>
  );
}

export default AdminImportChannels;

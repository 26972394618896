import UpdateIcon from "@mui/icons-material/Update";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";

import { Link, useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/Header.jsx";
import { useUserData } from "../../user.js";
import { formatISODate } from "../../utils/global.js";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage.js";
import CustomButton from "../global/CustomButton.jsx";
import CustomModal from "../global/CustomModal.jsx";
import CustomTabsTable from "../global/CustomTabsTable.jsx";
import { Loading } from "../global/Loading.jsx";
import RadialChart from "../global/RadialChart.jsx";

const colorScheme = {
  ready: {
    box: "rgba(34, 197, 94, 0.16)",
    text: "rgb(17, 141, 87)",
  },
  inProgress: {
    box: "rgba(255, 171, 0, 0.16)",
    text: "rgb(183, 110, 0)",
  },
  notStarted: {
    box: "rgba(255, 86, 48, 0.16)",
    text: "rgb(183, 29, 24)",
  },
};

const badgeFontStyles = {
  mainFontSize: ".8rem",
  digitFontSize: "1.2rem",
  chartForntSize: "1rem",
};

const paperStyle = {
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  borderRadius: "16px",
};

const formatStatus = (status) => {
  switch (status) {
    case "ready":
      return "Ready";
    case "notStarted":
      return "Not started";
    case "inProgress":
      return "In progress";
    default:
      return status;
  }
};

function Hardfork() {
  const { hardforkSlug } = useParams();
  const userData = useUserData();
  const [exchanges, setExchanges] = useState([]);
  const [hardfork, setHardfork] = useState([]);
  const [jwt] = useLocalState(null, "jwt");
  const location = useLocation();
  const [openLogsQuery, setOpenLogsQuery] = useState(
    new URLSearchParams(location.search).get("openLogs")
  );

  const [isModalViewOpen, setModalViewOpen] = useState(false);
  const [isModalAddOpen, setModalAddOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addLogInputValue, setAddLogInputValue] = useState("");
  const [addLogBaseEnitityIdValue, setAddLogBaseEnitityIdValue] = useState();
  const [addLogStatusValue, setAddLogStatusValue] = useState("");
  const [fetchedLogs, setFetchedLogs] = useState([]);
  const [liquidityPercentage, setLiquidityPercentage] = useState(0);
  const [liquidityPercentageColor, setLiquidityPercentageColor] =
    useState("rgb(255, 86, 48)");
  const [triggerHardforkUpdate, setTriggerHardforkUpdate] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [deleteHardforkExchangeSlug, setDeleteHardforkExchangeSlug] =
    useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "baseVolumes",
      sort: "desc",
    },
  ]);

  const tabsFunctions = {
    all: {
      func: function (data) {
        return data;
      },
      label: "All",
      color: "default",
    },
    ready: {
      func: function (data) {
        return data.filter((exchange) => {
          return exchange.baseEntity.statuses[0].status === "ready";
        });
      },
      label: "Ready",
      color: "green",
    },
    inProgress: {
      func: function (data) {
        return data.filter((exchange) => {
          return exchange.baseEntity.statuses[0].status === "inProgress";
        });
      },
      label: "In Progress",
      color: "yellow",
    },
    notStarted: {
      func: function (data) {
        return data.filter((exchange) => {
          return exchange.baseEntity.statuses[0].status === "notStarted";
        });
      },
      label: "Not Started",
      color: "red",
    },
  };

  function handleTextChange(value) {
    setAddLogInputValue(value);
  }

  const handleDeleteHardforkExchange = (row) => {
    setOpenConfirmDialog(true);
    setDeleteHardforkExchangeSlug(row.slug);
  };

  const handleStartusDropdownChange = (event) => {
    setAddLogStatusValue(event.target.value);
  };

  useEffect(() => {
    if (openLogsQuery && exchanges && exchanges.length > 0) {
      const slugInExchanges = exchanges.filter(
        (exchange) => exchange.slug === openLogsQuery
      );
      if (slugInExchanges.length > 0) {
        handleViewLogButtonClick(openLogsQuery);
        setOpenLogsQuery(null);
      }
    }
  }, [openLogsQuery, exchanges]);

  const handleViewLogButtonClick = (baseEntitySlug) => {
    setModalViewOpen(true);
    fetch(`${backendUrl}/logs/${hardfork.slug}/${baseEntitySlug}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedLogs(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleConfirmDeleteHardforkExchange = () => {
    fetch(
      `${backendUrl}/hardforks/${hardfork.slug}/exchanges/${deleteHardforkExchangeSlug}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Hardfork not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setTriggerHardforkUpdate(!triggerHardforkUpdate);
        setOpenConfirmDialog(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  function handleLockLiquidity() {
    setLoading(true);
    fetch(`${backendUrl}/hardforks/lock/${hardfork.slug}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTriggerHardforkUpdate(!triggerHardforkUpdate);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  }

  function handleRefreshHardfork() {
    setLoading(true);
    fetch(`${backendUrl}/hardforks/refresh/${hardfork.slug}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTriggerHardforkUpdate(!triggerHardforkUpdate);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  }

  const handleAddLogButtonClick = (row) => {
    setModalAddOpen(true);
    setAddLogStatusValue(row.baseEntity.statuses[0].status);
    setAddLogBaseEnitityIdValue(row.baseEntity.id);
  };

  useEffect(() => {
    const liquidityDone = exchanges
      .filter((exchange) => exchange.baseEntity.statuses[0]?.status === "ready")
      .reduce((sum, exchange) => sum + exchange.liquidityPercentage, 0);

    setLiquidityPercentage(liquidityDone);
  }, [exchanges]);

  useEffect(() => {
    if (liquidityPercentage <= 30) {
      setLiquidityPercentageColor("rgb(255, 86, 48)");
    } else if (liquidityPercentage > 30 && liquidityPercentage <= 80) {
      setLiquidityPercentageColor("rgb(255, 171, 0)");
    } else if (liquidityPercentage > 80) {
      setLiquidityPercentageColor("rgb(34, 197, 94)");
    } else {
      setLiquidityPercentageColor("rgb(255, 86, 48)");
    }
  }, [liquidityPercentage]);

  useEffect(() => {
    fetch(`${backendUrl}/hardforks/exchanges/${hardforkSlug}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setHardfork(data.hardfork);
        setExchanges(data.exchanges);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, triggerHardforkUpdate, backendUrl, hardforkSlug]);

  const handleCreateLogButtonClick = () => {
    setModalAddOpen(false);
    setAddLogInputValue("");
    fetch(`${backendUrl}/logs/`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        userId: userData.id,
        baseEntityId: addLogBaseEnitityIdValue,
        hardforkId: hardfork.id,
        content: addLogInputValue,
        status: addLogStatusValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTriggerHardforkUpdate(!triggerHardforkUpdate);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={params.row.logo}
              alt="logo"
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
            <Box>
              <Link
                to={`/entities/${params.row.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {params.formattedValue}
              </Link>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "baseVolumes",
      headerName: "Base Volume",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: (value) => {
        if (
          value.row.baseEntity &&
          value.row.baseEntity.statuses &&
          value.row.baseEntity.statuses[0].volume &&
          value.row.baseEntity.statuses[0].volume.volume
        ) {
          return value.row.baseEntity.statuses[0].volume.volume;
        }
        return 0;
      },
      valueFormatter: (value) => {
        return value.value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
      },
      flex: 0,
      width: 200,
    },
    {
      field: "liquidityPercentage",
      headerName: "Liquidity %",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueFormatter: (value) => {
        return `${value.value} %`;
      },
      flex: 0,
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                colorScheme[params.row.baseEntity.statuses[0].status].box,
              color: colorScheme[params.row.baseEntity.statuses[0].status].text,
              py: "5px",
              px: "7px",
              borderRadius: "7px",
              fontWeight: "700",
            }}
          >
            {formatStatus(params.row.baseEntity.statuses[0].status)}
          </Box>
        );
      },
    },
    {
      field: "logButton",
      headerName: "Actions",
      sortable: false,
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <>
                <CustomButton
                  buttonText={"View"}
                  icon={SummarizeIcon}
                  onClick={() =>
                    handleViewLogButtonClick(params.row.baseEntity.slug)
                  }
                  sx={{
                    padding: "5px 10px 5px 5px",
                    fontSize: "0.675rem",
                    width: "80px",
                  }}
                />
                {userData.isAdmin && (
                  <>
                    <span>
                      <CustomButton
                        buttonText={"Add Log"}
                        icon={AddIcon}
                        onClick={() => handleAddLogButtonClick(params.row)}
                        sx={{
                          padding: "5px 10px 5px 5px",
                          fontSize: "0.675rem",
                          width: "80px",
                        }}
                      />
                    </span>
                    <span>
                      <CustomButton
                        buttonText={"Remove"}
                        icon={RemoveIcon}
                        onClick={() => handleDeleteHardforkExchange(params.row)}
                        sx={{
                          padding: "5px 10px 5px 5px",
                          fontSize: "0.675rem",
                          width: "80px",
                        }}
                      />
                    </span>
                  </>
                )}
              </>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid item xs={12} display={"flex"}>
            <Grid item xs={6}>
              <Header
                title={hardfork.name}
                subtitle={
                  hardfork.description
                    ? `${hardfork.description.slice(0, 40)}...`
                    : "Hardfork information"
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                gap: "10px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <CustomButton
                  color={"blue"}
                  buttonText={
                    hardfork.isLockedLiquidity
                      ? "Unlock liquidity"
                      : "Lock liquidity"
                  }
                  icon={
                    hardfork.isLockedLiquidity
                      ? LockOpenOutlinedIcon
                      : LockOutlinedIcon
                  }
                  onClick={handleLockLiquidity}
                />
              )}
              {userData.isAdmin && (
                <CustomButton
                  color={"green"}
                  buttonText={"Refresh hardfork"}
                  icon={UpdateIcon}
                  onClick={handleRefreshHardfork}
                />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item container xs={12}>
              <Grid item container mt={"1%"} xs={12} spacing={3}>
                <Grid container item xs={3}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid
                      item
                      xs={11}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RadialChart
                        data={{
                          total: 100,
                          data: liquidityPercentage,
                        }}
                        showLabel={true}
                        startAngle={-90}
                        endAngle={90}
                        theme={`${liquidityPercentageColor}`}
                        label="Liquidity Ready"
                        offsetYLabel={-12}
                        offsetYValue={-10}
                      />
                    </Grid>
                  </Paper>
                </Grid>
                <Grid container item xs={3}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RadialChart
                        data={{
                          total:
                            hardfork.ready +
                            hardfork.inProgress +
                            hardfork.notStarted,
                          data: hardfork.ready,
                        }}
                        theme="rgb(34, 197, 94)"
                        label="Ready"
                      />
                    </Grid>
                    <Grid item xs={4} container textAlign={"center"}>
                      <Grid
                        item
                        xs={12}
                        alignItems={"end"}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        {" "}
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: "700",
                            fontSize: badgeFontStyles.digitFontSize,
                          }}
                        >
                          {hardfork.ready}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          fontSize: badgeFontStyles.mainFontSize,
                        }}
                      >
                        Ready
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid container item xs={3}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RadialChart
                        data={{
                          total:
                            hardfork.ready +
                            hardfork.inProgress +
                            hardfork.notStarted,
                          data: hardfork.inProgress,
                        }}
                        theme="rgb(255, 171, 0)"
                        label="In progress"
                      />
                    </Grid>
                    <Grid item xs={4} container textAlign={"center"}>
                      <Grid
                        item
                        xs={12}
                        alignItems={"end"}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        {" "}
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: "700",
                            fontSize: badgeFontStyles.digitFontSize,
                          }}
                        >
                          {hardfork.inProgress}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          fontSize: badgeFontStyles.mainFontSize,
                        }}
                      >
                        In progress
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid container item xs={3}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      display: "flex",
                      width: "100%",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RadialChart
                        data={{
                          total:
                            hardfork.ready +
                            hardfork.inProgress +
                            hardfork.notStarted,
                          data: hardfork.notStarted,
                        }}
                        theme="rgb(255, 86, 48)"
                        label="Not started"
                      />
                    </Grid>
                    <Grid item xs={4} container textAlign={"center"}>
                      <Grid
                        item
                        xs={12}
                        alignItems={"end"}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        {" "}
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: "700",
                            fontSize: badgeFontStyles.digitFontSize,
                          }}
                        >
                          {hardfork.notStarted}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          fontSize: badgeFontStyles.mainFontSize,
                        }}
                      >
                        Not started
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomTabsTable
                  sxPaper={{ height: "56vh" }}
                  sxTable={{
                    height: "auto",
                  }}
                  data={exchanges}
                  columns={columns}
                  tabs={tabsFunctions}
                  name={"hardfork"}
                  sortModel={sortModel}
                />
              </Grid>
            </Grid>
          </Grid>
          <CustomModal
            isOpen={isModalViewOpen}
            onClose={() => {
              setModalViewOpen(false);
              setFetchedLogs([]);
            }}
            sx={{ width: "50%" }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {fetchedLogs.length > 0 ? (
                fetchedLogs.map((log) => (
                  <Grid item xs={12} key={log.id}>
                    <Card elevation={0} sx={paperStyle}>
                      <CardHeader
                        title={
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                            }}
                          >
                            {log.user.displayName}
                          </Typography>
                        }
                        subheader={formatISODate(
                          log.timestamp,
                          "dd-MM-yyyy HH:mm"
                        )}
                        sx={{
                          pb: 0,
                        }}
                      ></CardHeader>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12} md={12} lg={12}>
                            <ReactQuill
                              value={log.content}
                              readOnly={true}
                              theme={"bubble"}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  No logs
                </Grid>
              )}
            </Grid>
          </CustomModal>
          <CustomModal
            isOpen={isModalAddOpen}
            onClose={() => setModalAddOpen(false)}
            sx={{
              width: "50%",
            }}
          >
            {addLogBaseEnitityIdValue && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCreateLogButtonClick();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      >
                        Log Message
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "8px",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <ReactQuill
                          theme={"snow"}
                          style={{
                            marginBottom: "50px",
                            height: "30vh",
                          }}
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline"],
                              [{ list: "bullet" }, "strike", "link"],
                              ["clean"],
                            ],
                          }}
                          placeholder="Write the log message here"
                          value={addLogInputValue}
                          onChange={handleTextChange}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <FormControl sx={{ width: "20%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={addLogStatusValue}
                        label="Status"
                        onChange={handleStartusDropdownChange}
                      >
                        <MenuItem value={"ready"}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: colorScheme["ready"].box,
                              color: colorScheme["ready"].text,
                              py: "5px",
                              px: "7px",
                              borderRadius: "7px",
                              fontWeight: "700",
                            }}
                          >
                            Ready
                          </Box>
                        </MenuItem>
                        <MenuItem value={"inProgress"}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: colorScheme["inProgress"].box,
                              color: colorScheme["inProgress"].text,
                              py: "5px",
                              px: "7px",
                              borderRadius: "7px",
                              fontWeight: "700",
                            }}
                          >
                            In progress
                          </Box>
                        </MenuItem>
                        <MenuItem value={"notStarted"}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: colorScheme["notStarted"].box,
                              color: colorScheme["notStarted"].text,
                              py: "5px",
                              px: "7px",
                              borderRadius: "7px",
                              fontWeight: "700",
                            }}
                          >
                            Not started
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CustomButton
                      disabled={addLogInputValue?.trim() === ""}
                      onClick={handleCreateLogButtonClick}
                      color={"black"}
                      buttonText={"Add"}
                      sx={{
                        width: "70px",
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </CustomModal>
          <Dialog
            PaperProps={{
              sx: {
                borderRadius: "16px",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                width: "400px",
                maxWidth: "90%",
              },
            }}
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
          >
            <DialogTitle>Confirm Remove Exchange</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove exchange from this hardfork?
                Logs are going to be saved.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton
                sx={{
                  width: "80px",
                }}
                onClick={() => {
                  setOpenConfirmDialog(false);
                  setDeleteHardforkExchangeSlug("");
                }}
                buttonText="Cancel"
              />
              <CustomButton
                sx={{
                  width: "80px",
                }}
                onClick={handleConfirmDeleteHardforkExchange}
                buttonText="Remove"
                color={"black"}
              />
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
}

export default Hardfork;

import { Grid, Typography } from "@mui/material";
import CustomCard from "../global/CustomCard";

const WalletDetails = ({ entity }) => {
  return (
    <Grid container spacing={3}>
      {/* GENERAL CARD */}

      <Grid item xs={4}>
        <CustomCard cardHeader={"General"}>
          <Grid container>
            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Native assets
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity?.hasNativeAssets ? "Yes" : "No"}
            </Grid>
            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Staking
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity?.hasStaking ? "Yes" : "No"}
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default WalletDetails;

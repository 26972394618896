import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";

const initExchangeFormFields = {
  name: "",
  hasNativeAssets: false,
  trustScore: "",
};

const initWalletFormFields = {
  name: "",
  hasNativeAssets: false,
  hasStaking: false,
  trustScore: "",
};

const CreateForm = ({ onSubmit, initDataWallet, initDataExchange }) => {
  const [jwt] = useLocalState(null, "jwt");
  const [errorMessage, setErrorMessage] = useState("");
  const [formType, setFormType] = useState("");
  const [formData, setFormData] = useState({
    exchange: initDataExchange
      ? { ...initDataExchange }
      : initExchangeFormFields,
    wallet: initDataWallet ? { ...initDataWallet } : initWalletFormFields,
  });
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleFormTypeDropdownChange = (event) => {
    setFormType(event.target.value);
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [formType]: {
        ...prevData[formType],
        [name]: value,
      },
    }));
  };

  const handleRadioChange = (e) => {
    setErrorMessage("");
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [formType]: {
        ...prevData[formType],
        [name]: checked,
      },
    }));
  }

  function determineBodyData() {
    switch (formType) {
      case "exchange":
        return { exchange: formData.exchange };
      case "wallet":
        return { wallet: formData.wallet };
      default:
        return {};
    }
  }

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetch(`${backendUrl}/entities/`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        name: formData[formType].name,
        type: formType,
        ...determineBodyData(),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("Entity already exists!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        onSubmit(data);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <FormControl sx={{ width: "40%" }}>
          <InputLabel id="entity-type-label">Type</InputLabel>
          <Select
            labelId="entity-type-label"
            id="entity-type"
            value={formType}
            required
            label="Entity type"
            onChange={handleFormTypeDropdownChange}
          >
            <MenuItem value={"exchange"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "5px",
                  px: "7px",
                  borderRadius: "7px",
                  fontWeight: "700",
                }}
              >
                Exchange
              </Box>
            </MenuItem>
            <MenuItem value={"wallet"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "5px",
                  px: "7px",
                  borderRadius: "7px",
                  fontWeight: "700",
                }}
              >
                Wallet
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        {formType === "exchange" && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <FormControl>
              <TextField
                id="name"
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                required
                sx={{ flex: "0.8" }}
                value={formData[formType].name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <InputLabel id="trust-score-label">Trust score</InputLabel>
              <Select
                labelId="trust-score-label"
                name="trustScore"
                id="trust-score"
                required
                value={formData[formType].trustScore}
                label="Trust score"
                onChange={handleInputChange}
              >
                <MenuItem value={"green"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "5px",
                      px: "7px",
                      borderRadius: "7px",
                      fontWeight: "700",
                    }}
                  >
                    Green
                  </Box>
                </MenuItem>
                <MenuItem value={"yellow"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "5px",
                      px: "7px",
                      borderRadius: "7px",
                      fontWeight: "700",
                    }}
                  >
                    Yellow
                  </Box>
                </MenuItem>
                <MenuItem value={"red"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "5px",
                      px: "7px",
                      borderRadius: "7px",
                      fontWeight: "700",
                    }}
                  >
                    Red
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            <InputLabel id="has-native-assets-label">Native assets</InputLabel>
            <RadioGroup
              name="hasNativeAssets"
              value={formData[formType].hasNativeAssets}
              onChange={handleRadioChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </Box>
        )}
        {formType === "wallet" && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <FormControl>
              <TextField
                id="name"
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                required
                sx={{ flex: "0.8" }}
                value={formData[formType].name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <InputLabel id="trust-score-label">Trust score</InputLabel>
              <Select
                labelId="trust-score-label"
                name="trustScore"
                id="trust-score"
                required
                value={formData[formType].trustScore}
                label="Trust score"
                onChange={handleInputChange}
              >
                <MenuItem value={"green"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "5px",
                      px: "7px",
                      borderRadius: "7px",
                      fontWeight: "700",
                    }}
                  >
                    Green
                  </Box>
                </MenuItem>
                <MenuItem value={"yellow"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "5px",
                      px: "7px",
                      borderRadius: "7px",
                      fontWeight: "700",
                    }}
                  >
                    Yellow
                  </Box>
                </MenuItem>
                <MenuItem value={"red"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "5px",
                      px: "7px",
                      borderRadius: "7px",
                      fontWeight: "700",
                    }}
                  >
                    Red
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            <InputLabel id="has-native-assets-label">Native assets</InputLabel>
            <RadioGroup
              name="hasNativeAssets"
              value={formData[formType].hasNativeAssets}
              onChange={handleRadioChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
            <InputLabel id="has-native-assets-label">Staking</InputLabel>
            <RadioGroup
              name="hasStaking"
              value={formData[formType].hasStaking}
              onChange={handleRadioChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </Box>
        )}
        <Box
          sx={{
            flex: "0.2",
            justifyContent: "flex-end",
            textAlign: "center",
          }}
        >
          {errorMessage && (
            <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
              {errorMessage}
            </Box>
          )}
          <CustomButton
            onClick={handleSubmit}
            disabled={
              !formType ||
              !formData[formType].name.trim() ||
              !formData[formType].trustScore ||
              formData[formType].hasNativeAssets === "" ||
              formData[formType].hasStaking === ""
            }
            buttonText={"Create"}
            color={"black"}
          />
        </Box>
      </Stack>
    </form>
  );
};

export default CreateForm;

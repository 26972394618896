import TelegramIcon from "@mui/icons-material/Telegram";
import { Box, Container, FormControl, Grid, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import CustomCard from "../global/CustomCard";
import CustomModal from "../global/CustomModal";
import TelegramLogin from "./TelegramLogin";

const Profile = () => {
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    slackUserId: "",
    telegramUserId: "",
    telegramCredentials: "",
    profileImageIPFS: "",
  });
  const [successMessage, setSuccessMessage] = useState(false);
  const [profile, setProfile] = useState();
  const [profileRequestTrigger, setProfileRequestTrigger] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [alertOpacity, setAlertOpacity] = useState(0);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleSubmitTelegram = () => {
    setProfileRequestTrigger(!profileRequestTrigger);
    setModalOpen(false);
  };

  useEffect(() => {
    fetch(`${backendUrl}/profiles/user`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProfile(data);
        const { displayName, email } = data.user;
        const {
          slackUserId,
          telegramUserId,
          telegramCredentials,
          profileImageIPFS,
        } = data;
        setFormData({
          displayName,
          email,
          slackUserId,
          telegramUserId,
          telegramCredentials,
          profileImageIPFS,
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, profileRequestTrigger]);

  const showAndFadeOutAlert = () => {
    setSuccessMessage(true);
    setAlertOpacity(1);
    setTimeout(() => {
      setAlertOpacity(0);
      setTimeout(() => setSuccessMessage(false), 500);
    }, 2000);
  };

  const handleRemoveTelegramCredentials = () => {
    const data = {
      telegramCredentials: "",
    };
    fetch(`${backendUrl}/profiles`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcement not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        showAndFadeOutAlert();
        setProfileRequestTrigger(!profileRequestTrigger);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  const handleSubmit = () => {
    fetch(`${backendUrl}/profiles`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcement not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        showAndFadeOutAlert();
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    let { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Container maxWidth={"xl"}>
      <Header title="Profile" subtitle="Managing your profile" />
      {successMessage && (
        <Alert
          severity="success"
          sx={{
            mt: "10px",
            opacity: alertOpacity,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          Saved
        </Alert>
      )}
      <Grid container spacing={3} mt={"5px"}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Grid item xs={3}>
              <CustomCard
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={
                      formData?.profileImageIPFS
                        ? `https://ipfs.io/ipfs/${formData?.profileImageIPFS}`
                        : `${process.env.PUBLIC_URL}/images/cardano-logo-dark.png`
                    }
                    alt="Cardano"
                    style={{
                      width: "80%",
                      height: "80%",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </CustomCard>
            </Grid>
            <Grid item xs={6}>
              <CustomCard
                sx={{
                  width: "100%",
                  height: "100%",
                  pt: "10px",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        gap: "10px",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "50%" }}>
                        <TextField
                          id="displayName"
                          label="Full Name"
                          name="displayName"
                          variant="outlined"
                          fullWidth
                          disabled
                          sx={{ width: "100%", mt: "10px" }}
                          value={formData?.displayName}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      <FormControl sx={{ width: "50%" }}>
                        <TextField
                          id="email"
                          label="Email"
                          name="email"
                          variant="outlined"
                          fullWidth
                          disabled
                          sx={{ width: "100%", mt: "10px" }}
                          value={formData?.email}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        gap: "10px",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "50%" }}>
                        <TextField
                          id="telegramUserId"
                          label="Telegram User ID"
                          name="telegramUserId"
                          variant="outlined"
                          fullWidth
                          sx={{ width: "100%", mt: "10px" }}
                          value={formData?.telegramUserId}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      <FormControl sx={{ width: "50%" }}>
                        <TextField
                          id="slackUserId"
                          label="Slack User ID"
                          name="slackUserId"
                          variant="outlined"
                          fullWidth
                          sx={{ width: "100%", mt: "10px" }}
                          value={formData?.slackUserId}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        gap: "10px",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          id="profileImageIPFS"
                          label="Profile Picture IPFS"
                          name="profileImageIPFS"
                          variant="outlined"
                          fullWidth
                          sx={{ width: "100%", mt: "10px" }}
                          value={formData?.profileImageIPFS}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        gap: "10px",
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: "17px",
                      }}
                    >
                      {formData?.telegramCredentials != "" ? (
                        <CustomButton
                          buttonText={"Disconnect telegram"}
                          sx={{
                            backgroundColor: "rgb(255, 230, 225)",
                            color: "rgb(183, 29, 24)",
                            "&:hover": {
                              backgroundColor: "#ffccc3",
                              boxShadow: "none",
                            },
                          }}
                          disabled={formData.telegramCredentials === ""}
                          onClick={handleRemoveTelegramCredentials}
                        />
                      ) : (
                        <CustomButton
                          buttonText={"Connect telegram"}
                          color={"blue"}
                          icon={TelegramIcon}
                          onClick={handleOpenModal}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          flex: "0.2",
                          justifyContent: "flex-end",
                          textAlign: "center",
                        }}
                      >
                        {errorMessage && (
                          <Box
                            sx={{ color: "red", marginTop: 2, marginBottom: 2 }}
                          >
                            {errorMessage}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: "15px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <CustomButton
                        onClick={handleSubmit}
                        color={"black"}
                        buttonText={"Save"}
                        sx={{
                          width: "70px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </CustomCard>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        sx={{
          width: "30%",
          maxHeight: "80%",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <TelegramLogin onSubmit={handleSubmitTelegram} />
      </CustomModal>
    </Container>
  );
};

export default Profile;

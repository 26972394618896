import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const AdminImportChannelsTable = ({
  sortedData,
  entities,
  onLoaded,
  handleAutocompleteChange,
}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      setRows(sortedData);

      setTimeout(() => {
        onLoaded();
      }, 4000);
    }
  }, [sortedData]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead
          sx={{
            backgroundColor: "#f4f6f8",
          }}
        >
          <TableRow>
            <TableCell>
              <Typography
                sx={{
                  fontWeight: "600",
                }}
              >
                Channel Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                sx={{
                  fontWeight: "600",
                }}
              >
                Entity
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.chatId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.channelName}
              </TableCell>
              <TableCell align="right">
                <Autocomplete
                  id={`${row.chatId}`}
                  options={entities}
                  value={
                    entities.find(
                      (entity) => entity.slug === row.baseEntitySlug
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    handleAutocompleteChange(event, newValue, row.chatId)
                  }
                  getOptionLabel={(option) =>
                    option.wallet
                      ? option.wallet.name
                      : option.exchange
                      ? option.exchange.name
                      : "Unknown"
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <img
                        src={
                          option.wallet
                            ? option.wallet.logo
                            : option.exchange
                            ? option.exchange.logo
                            : ""
                        }
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "10px",
                        }}
                      />
                      {option.wallet
                        ? option.wallet.name
                        : option.exchange
                        ? option.exchange.name
                        : "Unknown"}
                    </Box>
                  )}
                  sx={{ width: 200 }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminImportChannelsTable;

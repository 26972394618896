import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useUserData } from "../../user";
import CustomButton from "../global/CustomButton";
import CustomTabsTable from "../global/CustomTabsTable";

const ReportingTable = ({ components, onEdit, onDelete }) => {
  const userData = useUserData();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Link
                to={`/reporting/${params.row.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {params.formattedValue}
              </Link>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "components",
      headerName: "Components",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.row.components.length;
      },
    },
  ];

  if (userData.isAdmin) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const componentTypeData = {
          id: params.row.id,
          name: params.row.name,
          slug: params.row.slug,
          versionsURL: params.row.versionsURL
        };
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <>
              <CustomButton
                type={"edit"}
                buttonText={"Edit"}
                onClick={() => onEdit(componentTypeData)}
                sx={{
                  padding: "5px 10px 5px 5px",
                  fontSize: "0.675rem",
                  width: "80px",
                }}
              />

              <CustomButton
                buttonText={"Delete"}
                icon={DeleteIcon}
                onClick={() => onDelete(componentTypeData)}
                sx={{
                  padding: "5px 10px 5px 5px",
                  fontSize: "0.675rem",
                  width: "80px",
                }}
              />
            </>
          </Box>
        );
      },
    });
  }

  return (
    <CustomTabsTable
      sxPaper={{ height: "56vh", maxHeight: "450px" }}
      sxTable={{
        height: "auto",
      }}
      data={components}
      columns={columns}
      name={"reporting"}
    />
  );
};

export default ReportingTable;

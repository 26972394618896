import { format, parseISO } from "date-fns";

function turncateString(string, length) {
  if (string.length > length) {
    return `${string.slice(0, length - 3)}...`;
  }
  return string;
}

function formatISODate(date, formatString) {
  const parsedDate = parseISO(date);
  const formattedDate = format(parsedDate, formatString);
  return formattedDate;
}

function formatDate(date) {
  const d = new Date(date);
  return d.toISOString().split("T")[0];
}

export { formatDate, formatISODate, turncateString };


import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { cloneElement } from "react";

export const Badge = ({ theme = "green", title, subtitle, icon }) => {
  const themes = {
    green: {
      background:
        "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)), rgb(255, 255, 255)",
      color: "rgb(0, 75, 80)",
    },
    red: {
      background:
        "linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255)",
      color: "rgb(122, 9, 22)",
    },
    blue: {
      background:
        "linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)",
      color: "rgb(0, 55, 104)",
    },
  };

  return (
    <Stack
      direction="column"
      textAlign="center"
      alignItems="center"
      padding="50px 0"
      sx={{
        background: themes[theme].background,
        color: themes[theme].color,
        borderRadius: "16px",
      }}
    >
      {icon && cloneElement(icon, { style: { fontSize: '36px', width: '36px', height: '36px' } })}
      <Typography variant="h2" fontWeight={"bold"}>
        {title}
      </Typography>
      <Typography variant="h5">{subtitle}</Typography>
    </Stack>
  );
};

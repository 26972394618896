import EventBusyIcon from "@mui/icons-material/EventBusy";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import { useUserData } from "../../user";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import CustomModal from "../global/CustomModal";
import CustomTabsTable from "../global/CustomTabsTable";
import { Loading } from "../global/Loading";

const initForm = {
  name: "",
  description: "",
  releaseDate: null,
};
dayjs.extend(utc);

const Hardforks = () => {
  const userData = useUserData();
  const [jwt] = useLocalState(null, "jwt");
  const [errorMessage, setErrorMessage] = useState("");
  const [hardforks, setHardforks] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [formData, setFormData] = useState(initForm);
  const [formMode, setFormMode] = useState("create");
  const [editHardforkSlug, setEditHardforkSlug] = useState("");
  const [deleteHardforkId, setDeleteHardforkId] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(`${backendUrl}/hardforks`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setHardforks(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, requestTrigger]);

  function triggerHardforkUpdate() {
    setRequestTrigger(!requestTrigger);
  }

  // const handleDownload = (hardfork) => {
  //   fetch(`${backendUrl}/hardforks/exchanges/${hardfork.slug}`, {
  //     method: "GET",
  //     credentials: "include",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + jwt,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const updatedData = {
  //         hardfork: {
  //           name: hardfork.name,
  //           slug: hardfork.slug,
  //           description: hardfork.description,
  //           releaseDate: hardfork.releaseDate,
  //           ready: hardfork.ready,
  //           inProgress: hardfork.inProgress,
  //           notStarted: hardfork.notStarted,
  //         },
  //         exchanges: data.exchanges.map((exchange) => {
  //           if (exchange.baseVolumes.length > 0) {
  //             let mostRecent = exchange.baseVolumes.sort(
  //               (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  //             )[0];
  //             return {
  //               logo: exchange.logo,
  //               name: exchange.name,
  //               slug: exchange.slug,
  //               updatedOn: exchange.updatedOn,
  //               status: exchange.baseEntity.statuses[0].status,
  //               baseVolume: {
  //                 value: mostRecent.volume,
  //                 timestamp: mostRecent.timestamp,
  //               },
  //               liquidityPercentage: exchange.liquidityPercentage,
  //             };
  //           }
  //           return exchange;
  //         }),
  //       };

  //       const blob = new Blob([JSON.stringify(updatedData, null, 2)], {
  //         type: "application/json",
  //       });
  //       const link = document.createElement("a");
  //       link.download = `${hardfork.slug}.json`;
  //       link.href = window.URL.createObjectURL(blob);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });
  // };

  const tabsFunctions = {
    all: {
      func: function (data) {
        return data;
      },
      label: "All",
      color: "default",
    },
    upcoming: {
      func: function (data) {
        const todayUTC = new Date();
        todayUTC.setUTCHours(0, 0, 0, 0);
        return data.filter(
          (hardfork) =>
            hardfork.releaseDate == null ||
            new Date(hardfork.releaseDate) > todayUTC
        );
      },
      label: "Upcoming",
      color: "green",
    },
    past: {
      func: function (values) {
        const todayUTC = new Date();
        todayUTC.setUTCHours(0, 0, 0, 0);
        return values.filter(
          (hardfork) =>
            new Date(hardfork.releaseDate) <= todayUTC && hardfork.releaseDate
        );
      },
      label: "Past",
      color: "gray",
    },
  };

  const handleAddHardforkButtonClick = () => {
    setModalOpen(true);
    setFormMode("create");
    setFormData(initForm);
  };

  const handleEditHardforkbuttonClick = (row) => {
    setEditHardforkSlug(row.slug);
    setFormMode("edit");
    setFormData((prevData) => {
      return {
        name: row.name,
        description: row.description,
        releaseDate: row.releaseDate,
      };
    });
    setModalOpen(true);
  };

  const handleDeleteHardfork = (row) => {
    setOpenConfirmDialog(true);
    setDeleteHardforkId(row.id);
  };

  const handleConfirmDeleteHardfork = () => {
    fetch(`${backendUrl}/hardforks/${deleteHardforkId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Hardfork not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setRequestTrigger(!requestTrigger);
        setOpenConfirmDialog(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  const handleCreateHardforkButtonClick = () => {
    const method = formMode === "create" ? "POST" : "PUT";
    const url =
      formMode === "create"
        ? `${backendUrl}/hardforks/`
        : `${backendUrl}/hardforks/${editHardforkSlug}`;

    fetch(url, {
      method: method,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        triggerHardforkUpdate();
        setModalOpen(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleDateChange = (value) => {
    const date = dayjs(value);
    if (date.isValid()) {
      setFormData((prevData) => ({
        ...prevData,
        releaseDate: date.toISOString(),
      }));
    } else {
      console.error("Invalid date value");
    }
  };

  const handleClearDate = () => {
    setFormData((prevData) => ({
      ...prevData,
      ["releaseDate"]: null,
    }));
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Box key={params.id}>
            <Link
              to={`/hardforks/${params.row.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {params.value}
            </Link>
          </Box>
        );
      },
      flex: 2,
    },
    {
      field: "ready",
      headerName: "Ready",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "inProgress",
      headerName: "In Progress",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "notStarted",
      headerName: "Not Started",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "releaseDate",
      headerName: "Release Date",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "UTC",
          });
          return formattedDate;
        }
        return "TBA";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 2.5,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center", // Use space-around instead of space-between
            }}
          >
            <CustomButton
              buttonText={"Edit"}
              type={"edit"}
              onClick={() => handleEditHardforkbuttonClick(params.row)}
              sx={{
                padding: "5px 10px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            />
            {/* <CustomButton
              icon={InsertDriveFileIcon}
              buttonText={"JSON"}
              onClick={() => handleDownload(params.row)}
              sx={{
                padding: "5px 5px 5px 5px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            /> */}
            {userData.isAdmin && (
              <CustomButton
                type={"delete"}
                buttonText={"Delete"}
                onClick={() => handleDeleteHardfork(params.row)}
                sx={{
                  padding: "5px 10px",
                  fontSize: "0.675rem",
                  width: "80px",
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Header title="Hardforks" subtitle="Managing hardforks" />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <CustomButton
                  type={"create"}
                  onClick={handleAddHardforkButtonClick}
                  buttonText={"New Hardfork"}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomTabsTable
                data={hardforks}
                tabs={tabsFunctions}
                name={"hardforks"}
                columns={columns}
              />
            </Grid>
          </Grid>
          <CustomModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateHardforkButtonClick();
              }}
            >
              <Grid
                container
                gap={2}
                justifyContent={"center"}
                textAlign={"center"}
              >
                <Grid item xs={8}>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    required
                    sx={{ flex: "0.8" }}
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={8}
                  spacing={0.5}
                  display={"flex"}
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <Grid item xs={9}>
                    {" "}
                    <DatePicker
                      sx={{
                        width: "100%",
                      }}
                      label="Hardfork date"
                      timezone="UTC"
                      value={
                        formData.releaseDate
                          ? dayjs(formData.releaseDate)
                          : null
                      }
                      onChange={handleDateChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    {" "}
                    <CustomButton
                      icon={EventBusyIcon}
                      onClick={handleClearDate}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    type="text"
                    sx={{ flex: "0.8" }}
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      flex: "0.2",
                      justifyContent: "flex-end",
                    }}
                  >
                    {errorMessage && (
                      <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
                        {errorMessage}
                      </Box>
                    )}
                    <CustomButton
                      buttonText={formMode === "create" ? "Create" : "Update"}
                      color={"black"}
                      onClick={handleCreateHardforkButtonClick}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CustomModal>
          <Dialog
            PaperProps={{
              sx: {
                borderRadius: "16px",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                width: "400px",
                maxWidth: "90%",
              },
            }}
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
          >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this hardfork? All logs and
                statuses will be deleted and lost forever!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton
                sx={{
                  width: "80px",
                }}
                onClick={() => {
                  setOpenConfirmDialog(false);
                  setDeleteHardforkId("");
                }}
                buttonText="Cancel"
              />
              <CustomButton
                sx={{
                  width: "80px",
                }}
                onClick={handleConfirmDeleteHardfork}
                buttonText="Delete"
                color={"black"}
              />
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
};

export default Hardforks;

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";

const CreateEditChannelForm = ({ entity, onSubmit, mode, channel }) => {
  const [availableSubs, setAvailableSubs] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    ...channel,
    baseEntitySlug: entity.slug,
  });

  useEffect(() => {
    fetch(`${backendUrl}/channels/subscriptions`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAvailableSubs(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl]);

  const handleAutocompleteChange = (event, value) => {
    const selectedIds = value.map((sub) => sub.id);
    setFormData((prevData) => ({
      ...prevData,
      subscriptionIds: selectedIds,
    }));
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const url =
      mode === "update" && formData.id
        ? `${backendUrl}/channels/${formData.id}`
        : `${backendUrl}/channels/`;
    const method = mode === "update" ? "PUT" : "POST";
    if (mode === "update") {
      formData.isApproved = false;
    }
    fetch(url, {
      method: method,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            throw new Error("Channel already exists!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
        onSubmit();
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <FormControl>
            <TextField
              id="name"
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              required
              sx={{ flex: "0.8" }}
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              id="chatId"
              label="Chat id"
              name="chatId"
              variant="outlined"
              fullWidth
              required
              sx={{ flex: "0.8" }}
              value={formData.chatId}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <InputLabel id="trust-score-label">Channel type</InputLabel>
            <Select
              labelId="channel-type-label"
              name="channelType"
              id="channel-type"
              required
              value={formData.channelType}
              label="Channel type"
              onChange={handleInputChange}
            >
              <MenuItem value={"slack"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: "5px",
                    px: "7px",
                    borderRadius: "7px",
                    fontWeight: "700",
                  }}
                >
                  Slack
                </Box>
              </MenuItem>
              <MenuItem value={"telegram"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: "5px",
                    px: "7px",
                    borderRadius: "7px",
                    fontWeight: "700",
                  }}
                >
                  Telegram
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
          {availableSubs && (
            <FormControl>
              <Autocomplete
                name="subscriptionIds"
                onChange={handleAutocompleteChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={availableSubs.filter((sub) =>
                  formData.subscriptionIds.includes(sub.id)
                )}
                multiple
                id="tags-outlined"
                options={availableSubs}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Subscriptions"
                    placeholder="Add subscription"
                  />
                )}
              />
            </FormControl>
          )}
        </Box>

        <Box
          sx={{
            flex: "0.2",
            justifyContent: "flex-end",
            textAlign: "center",
          }}
        >
          {errorMessage && (
            <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
              {errorMessage}
            </Box>
          )}
          <CustomButton
            buttonText={"Save"}
            color={"black"}
            disabled={
              !formData.name.trim() ||
              !formData.channelType ||
              formData.chatId === ""
            }
            onClick={handleSubmit}
          />
        </Box>
      </Stack>
    </form>
  );
};

export default CreateEditChannelForm;

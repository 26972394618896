import { ResponsiveBar } from "@nivo/bar";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { turncateString } from "../../utils/global";

const BarChart = ({ data, indexBy, keys, legends }) => {
  const [dataBarChart, setDataBarChart] = useState(data);
  const [dataBarKeys, setDataBarKeys] = useState(keys);
  const [dataBarLegends, setDataBarLegends] = useState(legends);

  useEffect(() => {
    setDataBarChart(data);
    setDataBarKeys(keys);
    setDataBarLegends(legends);
  }, [data, keys, legends]);

  return (
    <ResponsiveBar
      data={dataBarChart}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 50, right: 110, bottom: 100, left: 90 }}
      padding={0.3}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat={">-,.2f"}
      colors={(date) => {
        return date.data[`${date.id}Color`];
      }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 1,
        tickPadding: 10,
        tickRotation: -80,
        legendOffset: 80,
        legendPosition: "middle",
        format: function formatXValues(value) {
          const parsedDate = parse(value, "dd-MM-yyyy HH:mm", new Date());
          return format(parsedDate, "dd-MM HH:mm");
        },
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -80,
        legendPosition: "middle",
        format: function formatYValues(value) {
          return value.toLocaleString();
        },
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          data: dataBarKeys.map((value) => {
            return {
              id: value,
              label: turncateString(dataBarLegends[value].name, 11),
              color: dataBarLegends[value].color,
            };
          }),
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.75,
          symbolSize: 8,
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={(e) => e.id}
    />
  );
};

export default BarChart;

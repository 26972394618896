import { ResponsiveLine } from "@nivo/line";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { turncateString } from "../../utils/global";

const LineChart = ({data}) => {
  const [dataLineChart, setDataLineChart] = useState(data);

  useEffect(() => {
    setDataLineChart(data);
  }, [data]);

  return (
    <ResponsiveLine
      data={dataLineChart.filter((data) => data.visible !== false)}
      margin={{ top: 50, right: 110, bottom: 100, left: 90 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      colors={(exchange) => {
        return exchange.color;
      }}
      curve="natural"
      lineWidth={4}
      yFormat={">-,.2f"}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 1,
        tickPadding: 10,
        tickRotation: -80,
        legendOffset: 80,
        legendPosition: "middle",
        format: function formatXValues(value) {
          const parsedDate = parse(value, "dd-MM-yyyy HH:mm", new Date());
          return format(parsedDate, "dd-MM HH:mm");
        }
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -80,
        legendPosition: "middle",
        format: function formatYValues(value) {
          return value.toLocaleString();
        },
      }}
      pointSize={7}
      pointColor={{ from: "color" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          data: dataLineChart.map((value) => {
            return {
              id: value.id,
              label: turncateString(value.name, 11),
              color: value.visible ? value.color : "grey",
            };
          }),
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 8,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
          onClick: (function(datum, event) {
            const updatedChartData = data.map((data) => {
              if (data.id === datum.id) {
                data.visible = !data.visible;
                return { ...data, visible: data.visible };
              }
              return data;
            });
            setDataLineChart(updatedChartData);
          })
        },
      ]}
    />
  );
};

export default LineChart;

import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SummarizeIcon from "@mui/icons-material/Summarize";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import {
  Accordion,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useLocation } from "react-router-dom";
import { Header } from "../../components/Header";
import { formatISODate } from "../../utils/global.js";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import { Loading } from "../global/Loading";

const colorSchemes = {
  announcements: {
    text: "rgb(255, 255, 255)",
    background: "rgb(33, 43, 54)",
  },
  base_entities: {
    text: "rgb(17, 141, 87)",
    background: "rgba(34, 197, 94, 0.16)",
  },
  hardforks: {
    text: "rgb(0, 108, 156)",
    background: "rgba(0, 184, 217, 0.16)",
  },
  logs: {
    text: "rgb(183, 110, 0)",
    background: "rgba(255, 171, 0, 0.16)",
  },
  channels: {
    text: "rgb(99, 115, 129)",
    background: "rgba(145, 158, 171, 0.16)",
  },
  red: {
    background: "rgba(255, 86, 48, 0.16)",
    text: "rgb(183, 29, 24)",
  },
};

const indexMapping = {
  announcements: "Announcements",
  base_entities: "Entities",
  hardforks: "Hardforks",
  logs: "Logs",
  channels: "Channels",
};

const iconsSx = {
  fontSize: "1.8rem",
};

const xsIndexMapping = {
  announcements: 6,
  base_entities: 0,
  hardforks: 0,
  logs: 4,
  channels: 0,
};

const iconIndexMapping = {
  announcements: <CampaignOutlinedIcon sx={iconsSx} />,
  base_entities: <CompareArrowsRoundedIcon sx={iconsSx} />,
  hardforks: <UpdateRoundedIcon sx={iconsSx} />,
  logs: <SummarizeIcon sx={iconsSx} />,
  channels: <ConnectedTvIcon sx={iconsSx} />,
};

const IndexMapper = {
  Channels: ({ entry }) => (
    <Link
      to={
        !entry.source.baseEntity
          ? `/admin/channels/?filter=chatId=${entry.source.chatId}&tab=pending`
          : `/entities/${entry.source.baseEntity.slug}?filter=chatId=${entry.source.chatId}&tab=channels`
      }
      style={{ textDecoration: "none", color: "inherit", width: "100%" }}
    >
      <Card
        elevation={0}
        sx={{
          borderRadius: "16px",
          backgroundColor: "#fcfcfc",
          border: "1px solid #e3e5e9",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
        }}
      >
        <CardContent
          sx={{
            padding: "10px!important",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                {entry.source.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: ".8rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {`${entry.source.channelType} / ${entry.source.chatId}`}
                {entry.source.baseEntity ? (
                  <>{` / ${entry.source.baseEntity.name}`}</>
                ) : (
                  <>
                    {` / `}
                    <HelpOutlineIcon sx={{ ml: "10px" }} />
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  ),
  Entities: ({ entry }) => (
    <Link
      to={`/entities/${entry.source.slug}`}
      style={{ textDecoration: "none", color: "inherit", width: "100%" }}
    >
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          alignItems: "center",
          borderRadius: "7px",
          padding: "5px",
          backgroundColor: "#fcfcfc",
          border: "1px solid #e3e5e9",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
        }}
      >
        <img
          src={entry.source[entry.source.entityType].logo}
          alt="logo"
          style={{
            width: "25px",
            height: "25px",
            marginRight: "10px",
          }}
        />
        {entry.source.name}
      </Box>
    </Link>
  ),
  Hardforks: ({ entry }) => (
    <Link
      to={`/hardforks/${entry.source.slug}`}
      style={{ textDecoration: "none", color: "inherit", width: "100%" }}
    >
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          alignItems: "center",
          borderRadius: "7px",
          padding: "10px",
          fontWeight: "700",
          gap: "5px",
          backgroundColor: "#fcfcfc",
          border: "1px solid #e3e5e9",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
        }}
      >
        <RestaurantIcon />
        {entry.source.name}
      </Box>
    </Link>
  ),
  Logs: ({ entry }) => (
    <Link
      to={`/hardforks/${entry.source.hardfork.slug}?openLogs=${entry.source.baseEntity.slug}`}
      style={{
        textDecoration: "none",
        color: "inherit",
        width: "100%",
      }}
    >
      <Card
        elevation={0}
        sx={{
          borderRadius: "16px",
          backgroundColor: "#fcfcfc",
          border: "1px solid #e3e5e9",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              {entry.source.user.displayName}
            </Typography>
          }
          subheader={`${formatISODate(
            entry.source.timestamp,
            "dd-MM-yyyy HH:mm"
          )} - ${entry.source.hardfork.name} - ${entry.source.baseEntity.name}`}
          sx={{
            pb: 0,
          }}
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <ReactQuill
                value={entry.source.content}
                readOnly={true}
                theme={"bubble"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  ),
  Announcements: ({ entry }) => (
    <Link
      to={`/announcements?openAnnouncement=${entry.source.id}`}
      style={{ textDecoration: "none", color: "inherit", width: "100%" }}
    >
      <Card
        elevation={0}
        sx={{
          borderRadius: "16px",
          backgroundColor: "#fcfcfc",
          border: "1px solid #e3e5e9",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              {entry.source.topic}
            </Typography>
          }
          sx={{
            pb: 0,
          }}
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} width={"100%"} display={"flex"}>
              <Grid container spacing={1}>
                {entry.source.subscriptions &&
                  entry.source.subscriptions.length > 0 && (
                    <>
                      {entry.source.subscriptions.map((subscription, index) => {
                        return (
                          <Grid item xs={3} textAlign={"center"} key={index}>
                            <Box
                              sx={{
                                backgroundColor: "#eeeff1",
                                color: "#637481",
                                padding: "5px",
                                borderRadius: "10px",
                                border: "1px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {subscription.name}
                            </Box>
                          </Grid>
                        );
                      })}
                    </>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ReactQuill
                value={entry.source.message}
                readOnly={true}
                theme={"bubble"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  ),
};

function SearchPage() {
  const [results, setResults] = useState([]);
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const query = new URLSearchParams(location.search).get("q");

  useEffect(() => {
    if ((query, jwt, backendUrl)) {
      setLoading(true);
      fetch(`${backendUrl}/search?q=${encodeURIComponent(query)}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => {
          setResults(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error!", error);
        });
    }
  }, [query, jwt, backendUrl]);

  return (
    <>
      {" "}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Header
            title="Search"
            subtitle={`Search results for: ${query ?? ""}`}
          />
          <Grid container spacing={3} mt={"10px"}>
            <Grid item xs={12}>
              {Object.keys(results).length === 0 ? (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"5px"}
                >
                  <SearchOffIcon fontSize="large" />
                  <Typography fontSize={"1.8rem"}>No Results</Typography>
                </Box>
              ) : (
                Object.keys(results).map((key, index) => (
                  <Accordion
                    key={index}
                    defaultExpanded
                    sx={{
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      "&::before": {
                        display: "none",
                      },
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          alignItems: "center",
                        },
                      }}
                    >
                      {iconIndexMapping[key]}&nbsp;&nbsp;
                      <Typography
                        sx={{
                          fontSize: "1.4rem",
                          fontWeight: "600",
                        }}
                      >
                        {`${indexMapping[key]}`}
                      </Typography>
                      <Box
                        component="span"
                        ml={1}
                        bgcolor={colorSchemes[key].background}
                        color={colorSchemes[key].text}
                        borderRadius="4px"
                        width="30px"
                        height="30px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        px={0.5}
                        py={0.2}
                        fontWeight={700}
                        fontSize={"1rem"}
                      >
                        {results[key].length}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={2}
                        maxHeight={"400px"}
                        overflow={"auto"}
                      >
                        {results[key].map((entry, index) => {
                          const ComponentToRender =
                            IndexMapper[indexMapping[key]] ||
                            IndexMapper.Default;
                          return (
                            <Grid
                              item
                              xs={xsIndexMapping[key]}
                              key={`grid-${index}`}
                            >
                              <ComponentToRender
                                key={`component-${index}`}
                                entry={entry}
                              />{" "}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export default SearchPage;

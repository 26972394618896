import { createContext, useContext, useState } from "react";

const UserContext = createContext();
const UserUpdateContext = createContext();
const UserTriggerUpdateContext = createContext();
const UserSetTriggerUpdateContext = createContext();

export function useSetTriggerUserUpdate() {
  return useContext(UserSetTriggerUpdateContext);
}

export function useTriggerUserUpdate() {
  return useContext(UserTriggerUpdateContext);
}

export function useUserData() {
  return useContext(UserContext);
}

export function useSetUserData() {
  return useContext(UserUpdateContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [triggerUserUpdate, setTriggerUserUpdate] = useState(false);

  return (
    <UserSetTriggerUpdateContext.Provider value={setTriggerUserUpdate}>
      <UserTriggerUpdateContext.Provider value={triggerUserUpdate}>
        <UserContext.Provider value={user}>
          <UserUpdateContext.Provider value={setUser}>
            {children}
          </UserUpdateContext.Provider>
        </UserContext.Provider>
      </UserTriggerUpdateContext.Provider>
    </UserSetTriggerUpdateContext.Provider>
  );
}

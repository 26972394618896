import { Box, Paper, Tabs } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CustomTab from "./CustomTab";

const colorSchemes = {
  default: {
    text: "rgb(255, 255, 255)",
    background: "rgb(33, 43, 54)",
  },
  green: {
    text: "rgb(17, 141, 87)",
    background: "rgba(34, 197, 94, 0.16)",
  },
  blue: {
    text: "rgb(0, 108, 156)",
    background: "rgba(0, 184, 217, 0.16)",
  },
  yellow: {
    text: "rgb(183, 110, 0)",
    background: "rgba(255, 171, 0, 0.16)",
  },
  gray: {
    text: "rgb(99, 115, 129)",
    background: "rgba(145, 158, 171, 0.16)",
  },
  red: {
    background: "rgba(255, 86, 48, 0.16)",
    text: "rgb(183, 29, 24)",
  },
};

const CustomTabsTable = ({
  sxTable,
  sxPaper,
  defaultValue,
  columns,
  name,
  data,
  tabs,
  columnOrder,
  onTabChange,
  sortModel,
  filterModel,
}) => {
  const [filteredData, setFilteredData] = useState();
  const [value, setValue] = useState(defaultValue || "all");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(undefined);
  const [sortModelValue, setSortModelValue] = useState();
  const [filterModelValue, setFilterModelValue] = useState();

  useEffect(() => {
    setSortModelValue(sortModel);
  }, [sortModel]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setFilterModelValue(filterModel);
  }, [filterModel]);

  function handleModelChange(model) {
    setColumnVisibilityModel(model);
    localStorage.setItem(`${name}VisibilityModel`, JSON.stringify(model));
  }

  const handleSortModelChange = (model) => {
    setSortModelValue(model.sortModel);
  };

  const handleFilterModelChange = (model) => {
    setFilterModelValue(model.filterModel);
  };

  useEffect(() => {
    if (tabs) {
      setFilteredData(tabs[value].func(data));
    } else {
      setFilteredData(data);
    }
  }, [data, value, tabs]);

  useEffect(() => {
    const userVisibilityModel = localStorage.getItem(`${name}VisibilityModel`);
    if (userVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(userVisibilityModel));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <Box
      m={"40px 0 0 0"}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-row": {
          borderBottom: "1px solid #e0e0e0",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderBottom: "none",
          backgroundColor: "#f4f6f8",
          color: "rgb(99, 115, 129)",
          fontSize: "13px",
        },
        "& .MuiDataGrid-virtualScroller": {},
        "& .MuiDataGrid-footerContainer": {
          paddingBottom: "0px",
          marginBottom: tabs ? "47px" : "",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "600 !important",
          overflow: "visible !important",
        },
        height: "75vh",
        ...sxTable,
      }}
    >
      <Paper
        sx={{
          height: "95%",
          borderRadius: "16px",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          ...sxPaper,
        }}
      >
        {tabs && (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              "& .MuiButtonBase-root": {
                textTransform: "none",
                marginRight: "40px",
              },
            }}
          >
            {Object.entries(tabs).map(([key, tab]) => {
              return (
                <CustomTab
                  key={key}
                  disableRipple
                  textColorOption={colorSchemes[tab.color].text}
                  textBackground={colorSchemes[tab.color].background}
                  value={key}
                  label={tab.label}
                  count={tab.func(data).length}
                  fontSize={"13px"}
                />
              );
            })}
          </Tabs>
        )}

        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleModelChange}
          rows={filteredData ? filteredData : []}
          columns={columns}
          sortModel={sortModelValue}
          columnOrder={columnOrder}
          onSortModelChange={handleSortModelChange}
          filterModel={filterModelValue}
          onFilterModelChange={handleFilterModelChange}
        />
      </Paper>
    </Box>
  );
};

export default CustomTabsTable;

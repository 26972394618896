import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useUserData } from "../../../user";
import { ErrorMessageContext } from "../../../utils/hooks/channels/errorMessageContext";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomButton from "../../global/CustomButton";
import CustomSwitch from "../../global/CustomSwitch";

const AdminSlackForm = ({ entities, profiles, onSubmit }) => {
  const { errorMessage, setErrorMessage } = useContext(ErrorMessageContext);
  const [jwt] = useLocalState(null, "jwt");
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    name: "",
    entitySlug: "",
    isPrivate: false,
    profileIds: [],
  });
  const userData = useUserData();

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      profileIds: [userData.profile.id],
    }));
  }, [userData]);

  const handleAutocompleteEntityChange = (event, value) => {
    const selectedSlug = value ? value.slug : "";
    setFormData((prevData) => ({
      ...prevData,
      entitySlug: selectedSlug,
    }));
  };

  const handleAutocompleteUsersChange = (event, value) => {
    const selectedIds = value.map((profile) => profile.id);
    setFormData((prevData) => ({
      ...prevData,
      profileIds: selectedIds,
    }));
  };

  const handleSwitchChange = (e) => {
    setErrorMessage("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      isPrivate: e.target.checked,
    }));
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateChannel = () => {
    setLoading(true);
    if (formData.name && formData.entitySlug) {
      fetch(`${backendUrl}/slack-bot/create/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || "There was an error processing your request."
            );
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          onSubmit();
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          setErrorMessage(error.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setErrorMessage("You need fill all reqeuired fields");
    }
  };

  return (
    <>
      <FormControl>
        <Tooltip title="Channel name should be lower case">
          <TextField
            id="name"
            label="Channel name"
            name="name"
            variant="outlined"
            required
            sx={{ flex: "0.8", width: "200px" }}
            value={formData.name}
            onChange={handleInputChange}
          />
        </Tooltip>
      </FormControl>
      <FormControl>
        <Autocomplete
          id="entities-channels"
          options={entities}
          onChange={handleAutocompleteEntityChange}
          getOptionLabel={(option) =>
            option.wallet
              ? option.wallet.name
              : option.exchange
              ? option.exchange.name
              : "Unknown"
          }
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <img
                src={
                  option.wallet
                    ? option.wallet.logo
                    : option.exchange
                    ? option.exchange.logo
                    : ""
                }
                alt=""
                style={{ width: "30px", height: "30px", marginRight: "10px" }}
              />
              {option.wallet
                ? option.wallet.name
                : option.exchange
                ? option.exchange.name
                : "Unknown"}
            </Box>
          )}
          sx={{ width: 200 }}
          renderInput={(params) => <TextField {...params} label="Entity" />}
        />
      </FormControl>
      <FormControl>
        <Autocomplete
          name="profileIds"
          onChange={handleAutocompleteUsersChange}
          sx={{ width: "200px" }}
          multiple
          id="tags-outlined"
          value={profiles.filter((profile) =>
            formData.profileIds.includes(profile.id)
          )}
          options={profiles.filter((profile) => profile.slackUserId)}
          getOptionLabel={(option) => option.user.displayName}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Users" placeholder="Invite users" />
          )}
        />
      </FormControl>
      <FormControlLabel
        sx={{
          width: "200px",
          display: "flex",
          justifyContent: "space-between",
        }}
        labelPlacement="start"
        control={
          <CustomSwitch
            checked={formData.isPrivate}
            onChange={handleSwitchChange}
            colorTheme="yellow"
            name="isPrivate"
          />
        }
        label={
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              ml: "2px",
            }}
          >
            Private
          </Typography>
        }
      />
      <Box
        sx={{
          flex: "0.2",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        {errorMessage && (
          <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
            {errorMessage}
          </Box>
        )}
        <CustomButton
          buttonText={"Create"}
          sx={{
            backgroundColor: "rgb(33, 43, 54)",
            color: "white",
          }}
          disabled={loading}
          onClick={handleCreateChannel}
        />
      </Box>
    </>
  );
};

export default AdminSlackForm;

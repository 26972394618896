import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import TruncateWords from "../global/TruncatedWords";

const EntityChannelsTable = ({ entity, onEdit, onDelete, filterModel }) => {
  const [channels, setChannels] = useState([]);
  const [filterModelValue, setFilterModelValue] = useState();

  useEffect(() => {
    setFilterModelValue(filterModel);
  }, [filterModel]);

  const handleFilterModelChange = (model) => {
    setFilterModelValue(model.filterModel);
  };

  useEffect(() => {
    const transformedChannels = (entity?.baseEntity?.channels || []).map(
      (channel) => {
        return {
          ...channel,
          subscriptions: channel.subscriptions || [],
        };
      }
    );

    setChannels(transformedChannels);
  }, [entity]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 170,
      flex: 1,
    },
    {
      field: "channelType",
      headerName: "Channel Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const value = params.value || "";
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
    {
      field: "chatId",
      headerName: "Chat ID",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "subscriptions",
      headerName: "Subscriptions",
      type: "string",
      width: "250",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <TruncateWords
            words={params.value.map((sub) => sub.name)}
            separator={", "}
            displayCount={3}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const { id, channelType, chatId, name, slug } = params.row;
        const channelData = {
          id,
          channelType,
          chatId,
          name,
          slug,
          subscriptionIds: params.row.subscriptions.map((sub) => sub.id),
        };
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type={"edit"}
              buttonText={"Edit"}
              onClick={() => onEdit(channelData)}
              sx={{
                padding: "5px 10px 5px 5px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            />
            <CustomButton
              type={"delete"}
              buttonText={"Delete"}
              onClick={() => onDelete(channelData)}
              sx={{
                padding: "5px 10px 5px 5px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      m={"1px 0 0 0"}
      height={"75vh"}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-row": {
          borderBottom: "1px solid #e0e0e0",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderBottom: "none",
          backgroundColor: "#f4f6f8",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          color: "rgb(99, 115, 129)",
          fontSize: "13px",
        },
        "& .MuiDataGrid-virtualScroller": {},
        "& .MuiDataGrid-footerContainer": {
          paddingBottom: "0px",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "600 !important",
          overflow: "visible !important",
        },
      }}
    >
      <Paper
        sx={{
          height: "95%",
          borderRadius: "16px",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "16px",
          }}
          rows={channels}
          columns={columns}
          filterModel={filterModelValue}
          onFilterModelChange={handleFilterModelChange}
        />
      </Paper>
    </Box>
  );
};

export default EntityChannelsTable;

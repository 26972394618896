import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputBase } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Search() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("q");

  function handleSubmit(e) {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
    }
  }

  useEffect(() => {
    if (query) {
      setSearchQuery(query);
    }
  }, [query]);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #e0e0e0",
          borderRadius: "7px",
          ml: 1,
          width: "300px",
          backgroundColor: "#f9f9f9",
          padding: "0",
        }}
      >
        <InputBase
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            flex: 1,
            fontSize: ".9rem",
            paddingLeft: "10px",
          }}
        />
        <IconButton
          type="submit"
          sx={{
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </form>
  );
}

export default Search;

import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/Header";
import { useUserData } from "../../user";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import CustomModal from "../global/CustomModal";
import CustomTabsTable from "../global/CustomTabsTable";
import { Loading } from "../global/Loading";
import AnnouncementsTable from "./AnnouncementsTable";
import CreateEditAnnouncementForm from "./CreateEditAnnouncementForm";

const defaultAnnouncement = {
  id: "",
  topic: "",
  message: "",
  subscriptionIds: [],
  excludeOptions: {
    channelIds: [],
    entityTypes: [],
    channelType: undefined,
  },
  scheduleDate: "",
};

const Announcements = () => {
  const userData = useUserData();
  const [isModalOpen, setModalOpen] = useState(false);
  const [jwt] = useLocalState(null, "jwt");
  const [mode, setMode] = useState("create");
  const [announcements, setAnnouncements] = useState();
  const [announcement, setAnnouncement] = useState(defaultAnnouncement);
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [openAnnouncementQuery, setOpenAnnouncementQuery] = useState(
    new URLSearchParams(location.search).get("openAnnouncement")
  );

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (openAnnouncementQuery && announcements && announcements.length > 0) {
      const idInAnnouncements = announcements.filter(
        (announcement) => announcement.id === Number(openAnnouncementQuery)
      );
      if (idInAnnouncements.length > 0) {
        const announcementData = {
          id: idInAnnouncements[0].id,
          topic: idInAnnouncements[0].topic,
          message: idInAnnouncements[0].message,
          subscriptionIds: idInAnnouncements[0].subscriptions.map(
            (sub) => sub.id
          ),
          scheduleDate: idInAnnouncements[0].scheduleDate,
          dateSent: idInAnnouncements[0].dateSent,
        };
        handleEdit(announcementData);
        setOpenAnnouncementQuery(null);
      }
    }
  }, [openAnnouncementQuery, announcements]);

  useEffect(() => {
    fetch(`${backendUrl}/announcements/`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcements not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setAnnouncements(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl, requestTrigger]);

  const handleEdit = (announcement) => {
    setAnnouncement(announcement);
    setMode("update");
    setModalOpen(true);
  };

  const handleApprove = (announcement) => {
    announcement.isApproved = true;
    fetch(`${backendUrl}/announcements/${announcement.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        ...announcement,
        isDraft: false,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcement not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
    setRequestTrigger(!requestTrigger);
  };

  const handleSend = (announcement) => {
    setLoading(true);
    fetch(`${backendUrl}/announcements/send`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        id: announcement.id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcement not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setRequestTrigger(!requestTrigger);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  const handleDelete = (announcement) => {
    setLoading(true);
    fetch(`${backendUrl}/announcements/${announcement.id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcement not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setRequestTrigger(!requestTrigger);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  const handleDownload = (reportId) => {
    setLoading(true);
    fetch(`${backendUrl}/announcements/announcements-reports/${reportId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Report not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent +=
          "Status, Chat Id, Channel Name, Channel Type, Error Details, Timestamp\n";
        data.reportData.forEach((row) => {
          let errorDetails = row.errorDetails
            ? `"${row.errorDetails.replace(/"/g, '""')}"`
            : "";
          csvContent +=
            [
              row.status,
              row.channel.chatId,
              `"${row.channel.name}"`,
              row.channel.channelType,
              errorDetails,
              `"${new Date(row.timestamp).toLocaleString()}"`,
            ].join(",") + "\n";
        });
        const encodedUri = encodeURI(csvContent);

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `report_${data.reportId}.csv`);
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  const handleSubmitForm = () => {
    setModalOpen(false);
    setRequestTrigger(!requestTrigger);
  };

  const handleNewAnnoucementButtonClick = () => {
    setAnnouncement(defaultAnnouncement);
    setMode("create");
    setModalOpen(true);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Header
                title={"Announcements"}
                subtitle={"Managing announcements"}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {userData.isAdmin && (
                <CustomButton
                  type={"create"}
                  buttonText={"New Announcement"}
                  onClick={handleNewAnnoucementButtonClick}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {announcements && (
                <AnnouncementsTable
                  announcements={announcements}
                  onEdit={handleEdit}
                  onApprove={handleApprove}
                  onSend={handleSend}
                  onDownload={handleDownload}
                  onDelete={handleDelete}
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            sx={{
              width: "70%",
            }}
          >
            <CreateEditAnnouncementForm
              announcement={announcement}
              mode={mode}
              onSubmit={handleSubmitForm}
            />
          </CustomModal>
        </Container>
      )}
    </>
  );
};

export default Announcements;

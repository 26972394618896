import { useEffect, useState } from "react";
import io from "socket.io-client";

const useComponentTypes = (jwt, backendUrl) => {
  const [socket, setSocket] = useState(null);
  const [componentTypesSocketData, setComponentTypesSocketData] =
    useState(null);

  useEffect(() => {
    if (jwt) {
      const newSocket = io(`${backendUrl}/socket/component-types-tracking`, {
        transports: ["websocket"],
        query: { token: jwt },
      });

      newSocket.on("connect", () => {
        console.log("Component types socket connected");
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      newSocket.on("connect_error", (err) => {
        console.error("Connect error:", err.message);
      });

      setSocket(newSocket);

      newSocket.on("componentsTypesChanged", (data) => {
        setComponentTypesSocketData(data);
      });

      return () => {
        newSocket.close();
      };
    }
  }, [jwt, backendUrl]);

  return { componentTypesSocketData };
};

export default useComponentTypes;

import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

const UserActivityContext = createContext();

export const useUserActivity = () => {
  return useContext(UserActivityContext);
};

export const UserActivityProvider = ({ children, jwt }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [socket, setSocket] = useState(null);
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    if (jwt) {
      const newSocket = io(`${backendUrl}/socket/users-online-status`, {
        transports: ["websocket"],
        query: {
          token: jwt,
        },
      });

      newSocket.on("connect", () => {
        console.log("Socket user status connected");
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      newSocket.on("connect_error", (err) => {
        console.error("Connect error:", err.message);
      });

      setSocket(newSocket);

      newSocket.on("userStatusChanged", (data) => {
        setUserStatus({
          userId: data.userId,
          isOnline: data.isOnline,
          lastActive: data.lastActive,
        });
      });

      return () => {
        newSocket.close();
      };
    }
  }, [jwt, backendUrl]);

  return (
    <UserActivityContext.Provider value={{ socket, userStatus }}>
      {children}
    </UserActivityContext.Provider>
  );
};

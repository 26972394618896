import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

const legendSize = {
  dimensions: "10px",
  textSize: ".6rem",
  xs: 4,
};

const ComponentTypesChart = ({ data, title }) => {
  const [dataComponentsChart, setDataComponentsChart] = useState(data);
  const [legendSizeResponsive, setLegendSizeResponsive] = useState(legendSize);

  useEffect(() => {
    if (data) {
      setDataComponentsChart(data);
      if (data.length > 6) {
        const itemsInRow = Math.ceil(data.length / 2);
        const xs = 12 / itemsInRow;

        const dimensions = Math.max(6, 10 - Math.ceil(itemsInRow / 4));
        const textSize = Math.max(0.4, 7 - Math.ceil(itemsInRow / 4));

        setLegendSizeResponsive({
          dimensions: `${dimensions}px`,
          xs: xs,
          textSize: `${textSize / 10}rem`,
        });
      }
    }
  }, [data]);

  function handleLegendClick(componentType) {
    setDataComponentsChart((prevData) => {
      return prevData.map((item) =>
        item.id === componentType.id
          ? { ...item, visible: !item.visible }
          : item
      );
    });
  }

  return (
    <Paper
      sx={{
        borderRadius: "16px",
        marginTop: "40px",
        width: "100%",
        height: "56vh",
        maxHeight: "450px",
        display: "flex",
        boxShadow:
          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            p: "15px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            p: "15px",
            alignSelf: "center",
            height: "300px",
          }}
        >
          <ResponsivePie
            data={dataComponentsChart.filter((data) => data.visible !== false)}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            padAngle={0.7}
            cornerRadius={3}
            colors={(componentType) => {
              return componentType.data.color;
            }}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            arcLinkLabelsColor={{ from: "color" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={0.3}
          sx={{
            height: "52px",
            alignSelf: "end",
            justifyContent: "center",
            padding: "5px",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          {dataComponentsChart &&
            dataComponentsChart.map((componentType) => {
              return (
                <Grid
                  item
                  key={componentType.id}
                  xs={legendSizeResponsive.xs}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, .03)",
                      borderRadius: "3px",
                    },
                  }}
                  onClick={() => handleLegendClick(componentType)}
                >
                  <Box
                    sx={{
                      width: legendSizeResponsive.dimensions,
                      height: legendSizeResponsive.dimensions,
                      mr: "5px",
                      backgroundColor: componentType.visible
                        ? componentType.color
                        : "grey",
                      borderRadius: "3px",
                    }}
                  />
                  <Tooltip title={componentType.label} arrow>
                    <Typography
                      sx={{
                        fontSize: legendSizeResponsive.textSize,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {componentType.label}
                    </Typography>
                  </Tooltip>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ComponentTypesChart;

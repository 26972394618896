import InfoIcon from "@mui/icons-material/Info";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../user";
import { ErrorMessageContext } from "../../../utils/hooks/channels/errorMessageContext";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CreateEntityForm from "../../entities/CreateForm";
import CustomButton from "../../global/CustomButton";

const AdminTelegramForm = ({ entities, profiles, onSubmit }) => {
  const { errorMessage, setErrorMessage } = useContext(ErrorMessageContext);
  const [loading, setLoading] = useState(false);
  const [createEntity, setCreateEntity] = useState(false);
  const navigate = useNavigate();
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formState, setFormState] = useState("login");
  const [formData, setFormData] = useState({
    name: "",
    entitySlug: "",
    isPrivate: false,
    profileIds: [],
  });
  const userData = useUserData();

  const handleEntityCreated = (createdEntity) => {
    setFormData((prevData) => ({
      ...prevData,
      entitySlug: createdEntity.slug,
    }));
    entities.push(createdEntity);
    setCreateEntity(false);
  };

  useEffect(() => {
    if (userData && userData.profile.telegramCredentials) {
      setFormState("create");
    }
  }, [userData]);

  const handleProfileRedirect = () => {
    navigate("/profile");
  };

  const handleAutocompleteEntityChange = (event, value, reason) => {
    event.preventDefault();
    setCreateEntity(false);
    if (reason === "selectOption" || reason === "createOption") {
      const entity = entities.find(
        (entity) => entity.name === (value.name || value)
      );
      if (!entity) {
        setCreateEntity(true);
        setFormData((prevData) => ({
          ...prevData,
          entityName: value.name || value,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          entitySlug: entity.slug,
          entityName: entity.name,
        }));
      }
    }
  };
  const handleAutocompleteUsersChange = (event, value) => {
    const selectedIds = value.map((profile) => profile.id);
    setFormData((prevData) => ({
      ...prevData,
      profileIds: selectedIds,
    }));
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateChannel = () => {
    setLoading(true);
    if (formData.name && formData.entitySlug) {
      fetch(`${backendUrl}/channels/create-remote/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({ ...formData, channelType: "telegram" }),
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || "There was an error processing your request."
            );
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          onSubmit();
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          setErrorMessage(error.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setErrorMessage("You need fill all reqeuired fields");
    }
  };
  return (
    <>
      {formState === "login" && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              color: "gray",
              alignItems: "center",
            }}
          >
            <InfoIcon />
            <Typography variant="body1">
              Please, connect your profile to telegram in order to create a
              channel.
            </Typography>
          </Box>
          <CustomButton
            buttonText={"Profile"}
            color={"blue"}
            icon={PersonOutlinedIcon}
            onClick={handleProfileRedirect}
          />
        </>
      )}
      {formState === "create" && (
        <>
          <FormControl>
            <Autocomplete
              id="entities-channels"
              options={entities}
              onChange={handleAutocompleteEntityChange}
              freeSolo
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : option.wallet
                  ? option.wallet.name
                  : option.exchange
                  ? option.exchange.name
                  : "Unknown"
              }
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <img
                    src={
                      option.wallet
                        ? option.wallet.logo
                        : option.exchange
                        ? option.exchange.logo
                        : ""
                    }
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "10px",
                    }}
                  />
                  {option.wallet
                    ? option.wallet.name
                    : option.exchange
                    ? option.exchange.name
                    : "Unknown"}
                </Box>
              )}
              sx={{ width: 200 }}
              renderInput={(params) => <TextField {...params} label="Entity" />}
            />
          </FormControl>
          {!createEntity ? (
            <>
              <FormControl>
                <Tooltip title="Channel name">
                  <TextField
                    id="name"
                    label="Channel name"
                    name="name"
                    variant="outlined"
                    required
                    sx={{ flex: "0.8", width: "200px" }}
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Tooltip>
              </FormControl>
              <FormControl>
                <Autocomplete
                  name="profileIds"
                  onChange={handleAutocompleteUsersChange}
                  sx={{ width: "200px" }}
                  multiple
                  id="tags-outlined"
                  options={profiles.filter((profile) => profile.telegramUserId)}
                  getOptionLabel={(option) => option.user.displayName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Users"
                      placeholder="Invite users"
                    />
                  )}
                />
              </FormControl>
              <Box
                sx={{
                  flex: "0.2",
                  justifyContent: "flex-end",
                  textAlign: "center",
                }}
              >
                {errorMessage && (
                  <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
                    {errorMessage}
                  </Box>
                )}
                <CustomButton
                  buttonText={"Create Channel"}
                  color={"black"}
                  sx={{
                    backgroundColor: "rgb(33, 43, 54)",
                    color: "white",
                  }}
                  disabled={loading || !formData.entitySlug || !formData.name}
                  onClick={handleCreateChannel}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "60%",
                margin: "auto",
                marginTop: "15px",
              }}
            >
              <CreateEntityForm
                onSubmit={handleEntityCreated}
                initDataExchange={{
                  name: formData.entityName,
                  hasNativeAssets: false,
                  trustScore: "",
                }}
                initDataWallet={{
                  name: formData.entityName,
                  hasNativeAssets: false,
                  hasStaking: false,
                  trustScore: "",
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AdminTelegramForm;

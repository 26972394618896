function getLatestVolume(exchange, type) {
  return (exchange[`${type}Volumes`][0]?.volume | 0).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

function getTradingPairs(exchange) {
  let assetNames = exchange.assets.map(asset => asset.name);
  return assetNames;
}

function prependZero(number) {
  // helper function to prepend '0' if number < 10
  return number < 10 ? "0" + number : number;
}

function formatDateForChart(date) {
  return `${prependZero(date.getDate())}-${prependZero(
    date.getMonth() + 1
  )}-${date.getFullYear()} ${prependZero(date.getHours())}:${prependZero(date.getMinutes())}`;
}


function formatAndSortVolumesForLineChart(exchange, type) {
  return exchange[`${type}Volumes`]
    .map((volume) => {
      const date = new Date(volume.timestamp);
      return {
        x: date,
        y: volume.volume,
        formattedDate: formatDateForChart(date),
      };
    })
    .reverse();
}


export { formatAndSortVolumesForLineChart, getLatestVolume, getTradingPairs };


import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import CustomButton from "../../global/CustomButton";
import { Loading } from "../../global/Loading";

function AdminAssignChannel({ channel, profiles, onSubmit }) {
  const [jwt] = useLocalState(null, "jwt");
  const [loadUpload, setLoadUpload] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    profileId: "",
    password: "",
    channelId: "",
  });

  const handleAssignChannel = () => {
    onSubmit();
    if (formData.profileId) {
      fetch(`${backendUrl}/telegram-api/transfer-owner/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || "There was an error processing your request."
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          // setErrorMessage(error.message);
        });
    }
  };

  const handleAutocompleteChange = (event, value) => {
    const selectedProfile = value ? value.id : "";
    setFormData((prevData) => ({
      ...prevData,
      profileId: selectedProfile,
    }));
  };

  return (
    <form>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            color: "gray",
            alignItems: "center",
          }}
        >
          <InfoIcon />
          <Typography variant="body1">
            The user will be added to the channel, if not there.
          </Typography>
        </Box>
        <FormControl>
          <Autocomplete
            name="profileIds"
            onChange={handleAutocompleteChange}
            sx={{ width: "200px" }}
            id="tags-outlined"
            options={profiles.filter((profile) => profile.telegramUserId)}
            getOptionLabel={(option) => option.user.displayName}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Users" placeholder="Invite users" />
            )}
          />
        </FormControl>

        <Box
          sx={{
            display: !loadUpload ? "none" : "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Box>

        <CustomButton
          disabled={loadUpload}
          color={"black"}
          onClick={handleAssignChannel}
          buttonText={"Assign"}
        />
      </Stack>
    </form>
  );
}

export default AdminAssignChannel;

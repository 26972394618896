import React, { createContext, useState } from 'react';

export const ErrorMessageContext = createContext();

export const ErrorMessageProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
    </ErrorMessageContext.Provider>
  );
};
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessageProvider } from "../../../utils/hooks/channels/errorMessageContext";
import { useLocalState } from "../../../utils/hooks/global/useLocalStorage";
import AdminSlackForm from "./AdminSlackForm";
import AdminTelegramForm from "./AdminTelegramForm";

const AdminCreateChannelForm = ({ profiles, entities, onSubmit }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [formData, setFormData] = useState({
    channelType: "",
  });

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <FormControl sx={{ width: "200px" }}>
          <InputLabel id="channel-type-label">Channel type</InputLabel>
          <Select
            labelId="channel-type-label"
            name="channelType"
            id="channel-type"
            required
            value={formData.channelType}
            label="Channel type"
            onChange={handleInputChange}
          >
            <MenuItem value={"slack"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "5px",
                  px: "7px",
                  borderRadius: "7px",
                  fontWeight: "700",
                }}
              >
                Slack
              </Box>
            </MenuItem>
            <MenuItem value={"telegram"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "5px",
                  px: "7px",
                  borderRadius: "7px",
                  fontWeight: "700",
                }}
              >
                Telegram
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <ErrorMessageProvider>
          {formData.channelType == "telegram" && (
            <AdminTelegramForm
              entities={entities}
              profiles={profiles}
              onSubmit={onSubmit}
            />
          )}
          {formData.channelType == "slack" && (
            <AdminSlackForm
              entities={entities}
              profiles={profiles}
              onSubmit={onSubmit}
            />
          )}
        </ErrorMessageProvider>
      </Stack>
  );
};

export default AdminCreateChannelForm;
